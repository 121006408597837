import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getSession, setSession } from "../../config/session";
import api from "../../config/api";
import {
  getInventory,
  getProductById,
  getMarkdownList,
  addReject,
  getRejectById,
  deletemarkdown,
  updateReject,
  exportMarkdownList,
} from "../../layouts/Admin/actions/InventoryActions";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import queryString from "query-string";
import Pagination from "react-js-pagination";
import { PulseLoader } from "react-spinners";
import NotificationAlert from "react-notification-alert";
import LoadingOverlay from "react-loading-overlay";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import defaultProductImage from "../../assets/img/product-image-default.png";
import { startOfDay, endOfDay } from "date-fns";

import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Row,
  Col,
  Label,
  InputGroup,
  InputGroupText,
  Media,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";

import Select from "react-select";

class ProductMarkdown extends React.Component {
  constructor(props) {
    const defaultPage = JSON.parse(getSession("defaultPage"));
    const userData = JSON.parse(getSession("userData"));
    const datesStr = new Date().toISOString();
    super(props);
    this.state = {
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
      startDateStr: datesStr,
      endDateStr: datesStr,
      markdowns: [],
      checked: [],
      defaultPage: "",
      pagination: {},
      activePage: 1,
      selectedPage: "",
      pageName: "",
      sortBy: "",
      sort: "",
      keyword: "",
      stockStatus: "",
      kind: "",
      size: 25,
      inventoryProducts: [],
      selectedProduct: null,
      reject: null,
      selectedReject: null,
      placeId: defaultPage,
      user: userData && userData.userId ? userData.userId : "",
      isLoading: true,
      isProcessing: false,
      isGrocery: false,
      hideTutorials: false,
      addRejectModalError: "",
      submittedModal: false,
      modal: false,
      modalView: false,
    };
  }

  componentDidMount() {
    const userData = JSON.parse(getSession("userData"));
    let pageInfo = JSON.parse(getSession("pageInfo"));
    if (pageInfo && pageInfo.pageType === "5cd141d10d437be772373ddb") {
      this.setState({ isGrocery: true });
    }

    if (userData !== null) {
      let defaultPage = JSON.parse(getSession("defaultPage"));
      const sessionToken = userData.sessionToken;
      console.log(defaultPage);
      if (defaultPage) {
        if (defaultPage !== "") {
          let url = this.props.location.search;
          let query = queryString.parse(url);
          let activePage = query.page ? Number(query.page) : 1;
          let status = query.status ? query.status : "";
          let sortBy = query.sortBy ? query.sortBy : "created";
          let sort = query.sort ? query.sort : "desc";
          let keyword = query.keyword ? query.keyword : "";
          let size = query.size ? query.size : 25;
          let kind = query.kind ? query.kind : "";
          let stockStatus = query.stockStatus ? query.stockStatus : "";
          if (query.category) {
          } else {
            delete query.subCategory;
          }
          let queryStr = "?" + queryString.stringify(query);
          this.setState({
            activePage: activePage,
            selectedPage: defaultPage,
            status: status,
            sort: sort,
            sortBy: sortBy,
            keyword: keyword,
            kind,
            stockStatus,
            size,
            pageName: pageInfo && pageInfo.name ? pageInfo.name : "",
            hideTutorials:
              pageInfo &&
              pageInfo.hideTutorials &&
              pageInfo.hideTutorials === true
                ? true
                : false,
          });
          this.refreshList(queryStr, defaultPage);
          this.props.getInventory(defaultPage, {}, sessionToken, (err, res) => {
            if (!err && res) {
              if (
                res.docs &&
                res.docs instanceof Array &&
                res.docs.length > 0
              ) {
                let products = [];
                res.docs.forEach((item) => {
                  const productItem = {
                    value: item.id,
                    label: item.name,
                  };
                  products.push(productItem);
                });
                this.setState({ inventoryProducts: products });
              }
            }
          });
        } else {
          this.props.getProviderPlaces(sessionToken, (error, result) => {
            if (!error && result) {
              if (
                result.places &&
                result.places instanceof Array &&
                result.places.length > 0
              ) {
                const defaultPlace = result.places.find(
                  (item) => item.isDefault
                );
                if (defaultPlace && defaultPlace._id) {
                  defaultPage = defaultPlace._id;
                  pageInfo = defaultPlace;
                } else {
                  defaultPage = result.places[0]._id;
                  pageInfo = result.places[0];
                }
              }
              setSession("pageInfo", JSON.stringify(pageInfo));
              let url = this.props.location.search;
              let query = queryString.parse(url);
              let activePage = query.page ? Number(query.page) : 1;
              let status = query.status ? query.status : "";
              let sortBy = query.sortBy ? query.sortBy : "created";
              let sort = query.sort ? query.sort : "desc";
              let keyword = query.keyword ? query.keyword : "";
              let size = query.size ? query.size : 25;
              let kind = query.kind ? query.kind : "";
              let stockStatus = query.stockStatus ? query.stockStatus : "all";
              let queryStr = "?" + queryString.stringify(query);
              this.setState({
                activePage: activePage,
                selectedPage: defaultPage,
                status: status,
                sort: sort,
                sortBy: sortBy,
                keyword: keyword,
                kind,
                stockStatus,
                size: size,
                pageName: pageInfo && pageInfo.name ? pageInfo.name : "",
              });
              if (
                pageInfo &&
                pageInfo.pageType === "5cd141d10d437be772373ddb"
              ) {
                this.setState({ isGrocery: true });
              }
              this.refreshList(queryStr, defaultPage);
            } else {
              this.setState({ isLoading: false });
            }
          });
        }
      } else {
        this.props.getProviderPlaces(sessionToken, (error, result) => {
          if (!error && result) {
            if (
              result.places &&
              result.places instanceof Array &&
              result.places.length > 0
            ) {
              const defaultPlace = result.places.find((item) => item.isDefault);
              if (defaultPlace && defaultPlace._id) {
                defaultPage = defaultPlace._id;
                pageInfo = defaultPlace;
              } else {
                defaultPage = result.places[0]._id;
                pageInfo = result.places[0];
              }
              setSession("pageInfo", JSON.stringify(pageInfo));
              let url = this.props.location.search;
              let query = queryString.parse(url);
              let activePage = query.page ? Number(query.page) : 1;
              let status = query.status ? query.status : "";
              let sortBy = query.sortBy ? query.sortBy : "created";
              let sort = query.sort ? query.sort : "desc";
              let keyword = query.keyword ? query.keyword : "";
              let size = query.size ? query.size : 25;
              let kind = query.kind ? query.kind : "";
              let stockStatus = query.stockStatus ? query.stockStatus : "all";
              let queryStr = "?" + queryString.stringify(query);
              this.setState({
                activePage: activePage,
                selectedPage: defaultPage,
                status: status,
                sort: sort,
                sortBy: sortBy,
                keyword: keyword,
                kind,
                stockStatus,
                size: size,
                pageName: pageInfo.name,
              });
              if (
                pageInfo &&
                pageInfo.pageType === "5cd141d10d437be772373ddb"
              ) {
                this.setState({ isGrocery: true });
              }
              this.refreshList(queryStr, defaultPage);
            } else {
              this.setState({ isLoading: false });
            }
          } else {
            this.setState({ isLoading: false });
          }
        });
      }
    } else {
      this.props.history.push("/login");
      window.location.reload();
    }
    this._isMount = true;
  }

  componentWillUnmount() {
    this._isMount = false;
  }

  handleDelete = (e) => {
    if (!window.confirm("Do you want to delete this markdown?")) {
      return false;
    }
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    const message = "Markdown has been deleted successfully.";
    let queryStr = "?" + queryString.stringify(query);
    const productId = e.currentTarget.dataset.item;
    if (productId) {
      const userData = JSON.parse(getSession("userData"));
      if (userData) {
        const sessionToken = userData.sessionToken;
        this.props.deletemarkdown(productId, sessionToken, (error, result) => {
          if (!error && result) {
            if (
              result.status === 200 ||
              result.status === 201 ||
              result.status === 204
            ) {
              this.showNotification(message);
              this.refreshList(queryStr, defaultPage);
            }
          } else {
            if (error) {
              if (
                error.response &&
                error.response.status &&
                error.response.status === 403
              ) {
                setTimeout(() => {
                  this.setState({ isLoading: true });
                  let defaultPage = JSON.parse(getSession("defaultPage"));
                  let url = this.props.location.search;
                  let query = queryString.parse(url);
                  delete query.message;
                  delete query.page;
                  let queryStr = "?" + queryString.stringify(query);
                  this.setState({ activePage: 1 });
                  this.refreshList(queryStr, defaultPage);
                }, 1000);
                this.showNotificationError(
                  "You are not allowed to delete this markdown."
                );
              } else {
                this.showNotificationError(error.response);
              }
            }
          }
        });
      } else {
        this.props.history.push("/login");
        window.location.reload();
      }
    }
  };
  renderProducts(products) {
    if (products instanceof Array && products.length > 0) {
      if (products.length === 1) {
        return products.map(function (e) {
          return (
            <Badge color="primary" pill>
              {e.name}
            </Badge>
          );
        });
      } else {
        return products.map(function (e) {
          return (
            <Badge color="primary" pill>
              {e.name}
            </Badge>
          );
        });
      }
    }
  }
  renderRows(markdowns) {
    if (this.state.isLoading) {
      return (
        <tr>
          <td colSpan={5}>
            <PulseLoader
              sizeUnit={"px"}
              size={15}
              color={"#1d8cf8"}
              loading={this.state.isLoading}
            />
          </td>
        </tr>
      );
    } else {
      if (markdowns instanceof Array && markdowns.length > 0) {
        return markdowns.map((item, index) => (
          <tr key={index}>
            <td>
              <Link
                to={"/product-markdown/" + item.id + "/edit"}
                className="text-medium"
              >
                {" "}
                {format(new Date(item.createdAt), "MMM dd, yyyy")}
              </Link>
            </td>
            <td>{this.renderProducts(item.products)}</td>

            <td>
              {format(new Date(item.effectivityDate.fromDate), "MMM/dd/yyyy")} -
              {format(new Date(item.effectivityDate.toDate), "MMM/dd/yyyy")}
            </td>

            <td className="text-medium">
              {item.user.firstName} {item.user.lastName}
            </td>

            <td className="text-medium">
              <Link
                to={"/product-markdown/" + item.id + "/edit"}
                style={{ padding: "0 0.1rem" }}
                title="View"
              >
                <Fa icon="edit" />
              </Link>{" "}
              <Link
                to="#"
                className="text-danger"
                onClick={this.handleDelete}
                data-item={item.id}
              >
                <Fa icon="trash-alt" />
              </Link>{" "}
            </td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={5}>
              <h5 className="text-danger">
                <em>No item(s) found.</em>
              </h5>
            </td>
          </tr>
        );
      }
    }
  }

  renderCategories(categories) {
    if (categories instanceof Array && categories.length > 0) {
      if (categories.length === 1) {
        return categories.map((cat) => cat.name);
      } else {
        return categories.map((cat) => cat.name + ", ");
      }
    }
  }

  renderImage(item) {
    const { name, photos } = item;

    let primaryPhoto;
    if (photos[0] !== undefined || photos[0] != null) {
      primaryPhoto = photos[0];
    } else {
      primaryPhoto = {
        thumb: defaultProductImage,
      };
    }
    return (
      <Media className="mt-1">
        {primaryPhoto && primaryPhoto.thumb && (
          <Media left middle>
            <Media
              object
              data-src={primaryPhoto.thumb}
              src={primaryPhoto.thumb}
              alt={name}
              title={name}
            />
          </Media>
        )}
      </Media>
    );
  }

  renderPlaces() {
    if (this.state.places !== undefined || this.state.places != null) {
      return this.state.places.map((item, index) => (
        <option key={index} value={item._id.toString()}>
          {item.name}
        </option>
      ));
    }
  }

  renderVolumes() {
    if (
      this.state.selectedProduct !== null &&
      this.state.selectedProduct.volumes !== null
    ) {
      const volumes = this.state.selectedProduct.volumes;
      return volumes.map((item, index) => (
        <option key={index} value={item.id}>
          {item.description}
        </option>
      ));
    }
  }

  renderVariations() {
    if (
      this.state.selectedProduct !== null &&
      this.state.selectedProduct.variations !== null
    ) {
      const variations = this.state.selectedProduct.variations;
      return variations.map((item, index) => (
        <option key={index} value={item.id}>
          {item.description}
        </option>
      ));
    }
  }

  toggleSwitchIsActive(item) {
    const userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;
    const itemId = item.id;
    if (item) {
      this.props.updateProduct(item, itemId, sessionToken, (error, result) => {
        if (result) {
          this.showNotification("Product successfully updated.");
        } else {
          if (error) {
            this.showNotificationError(error.response);
          } else {
            this.showNotificationError("Product not updated.");
          }
        }
      });
    }
  }

  onDismiss = () => {
    this.setState({ hideTutorials: true });
    let pageInfo = JSON.parse(getSession("pageInfo"));
    pageInfo.hideTutorials = true;
    setSession("pageInfo", JSON.stringify(pageInfo));
  };

  onHideTutorials = () => {
    if (
      !window.confirm("Are you sure you want to hide this section permanently?")
    ) {
      return false;
    }
    let pageInfo = JSON.parse(getSession("pageInfo"));
    let userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;
    const place = {
      hideTutorials: true,
    };
    api(sessionToken)
      .patch(`/provider/places/${pageInfo._id}`, place)
      .then((response) => {
        if (response && response.data && response.data.status === "ok") {
          this.setState({ hideTutorials: true });
          pageInfo.hideTutorials = true;
          setSession("pageInfo", JSON.stringify(pageInfo));
        } else {
          this.showNotificationError(
            "An unknown error occured. Please try again!"
          );
        }
      })
      .catch((error) => {
        this.setState({ submitted: false, isSaving: false });
        if (error.response && typeof error.response === "string") {
          this.showNotificationError(error.response);
        } else {
          this.showNotificationError(
            "An unknown error occured. Please try again!"
          );
        }
      });
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
    this.setState({
      selectedProduct: null,
      reject: null,
      addRejectModalError: "",
    });
  };

  toggleViewModal = () => {
    this.setState((prevState) => ({
      modalView: !prevState.modalView,
    }));
    this.setState({ selectedReject: null });
  };

  showNotification(message) {
    if (message && typeof message === "string") {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "success",
        icon: "",
        autoDismiss: 2,
      };
      if (
        notification &&
        this.refs.notify &&
        this.refs.notify.notificationAlert
      ) {
        this.refs.notify.notificationAlert(notification);
      }
    }
  }

  showNotificationError(message) {
    if (message && typeof message === "string") {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "danger",
        icon: "",
        autoDismiss: 2,
      };
      if (
        notification &&
        this.refs.notify &&
        this.refs.notify.notificationAlert
      ) {
        this.refs.notify.notificationAlert(notification);
      }
    }
  }

  handleChangeKeyword = (e) => {
    let { value } = e.target;
    this.setState({ keyword: value });
  };

  handleEnter = (e) => {
    let { key } = e;
    if (key === "Enter") {
      this.setState({ isLoading: true, activePage: 1 });
      let { keyword } = this.state;

      let defaultPage = JSON.parse(getSession("defaultPage"));
      let url = this.props.location.search;
      let query = queryString.parse(url);
      delete query.message;
      delete query.page;
      if (keyword !== "") {
        query.keyword = keyword.trim();
      } else {
        delete query.keyword;
      }
      let queryStr = "?" + queryString.stringify(query);
      this.refreshList(queryStr, defaultPage);
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ isLoading: true });
    let defaultPage = JSON.parse(getSession("defaultPage"));

    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    query.page = pageNumber;
    let queryStr = "?" + queryString.stringify(query);

    this.setState({ activePage: pageNumber });
    this.refreshList(queryStr, defaultPage);
  };

  handleChangePerPage = (e) => {
    this.setState({ isLoading: true });
    let { value } = e.target;

    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    delete query.page;
    if (value !== "") {
      query.size = value;
    } else {
      delete query.size;
    }
    let queryStr = "?" + queryString.stringify(query);
    this.setState({ size: value, activePage: 1 });
    this.refreshList(queryStr, defaultPage);
  };

  handleCheckAll = (e) => {
    let { checked: isChecked } = e.target;
    const checkboxes = document.querySelectorAll('input[name="item"]');
    const checkboxesArr = Array.from(checkboxes);
    if (isChecked) {
      const checked = checkboxesArr.map((item) => item.value);
      this.setState({ checked: checked });
    } else {
      this.setState({ checked: [] });
    }
    checkboxes.forEach((checkbox) => {
      if (isChecked) {
        checkbox.checked = true;
      } else {
        checkbox.checked = false;
      }
    });
  };

  handleCheck = (e) => {
    let { value, checked: isChecked } = e.target;
    if (isChecked) {
      this.setState({ checked: [...this.state.checked, value] });
    } else {
      const checked = this.state.checked.filter((item) => value !== item);
      this.setState({ checked: checked });
    }
  };

  sortCreated = () => {
    let sort = "";
    if (this.state.sortBy === "created") {
      if (this.state.sort !== "desc") {
        sort = "desc";
      } else {
        sort = "asc";
      }
    } else {
      sort = "desc";
    }
    let sortBy = "created";
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    query.sort = sort;
    query.sortBy = sortBy;
    let queryStr = "?" + queryString.stringify(query);
    this.setState({ sort: sort });
    this.setState({ sortBy: sortBy });
    this.refreshList(queryStr, defaultPage);
  };

  sortUpdated = () => {
    let sort = "";
    if (this.state.sortBy === "updated") {
      if (this.state.sort !== "desc") {
        sort = "desc";
      } else {
        sort = "asc";
      }
    } else {
      sort = "desc";
    }
    let sortBy = "updated";
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    query.sort = sort;
    query.sortBy = sortBy;
    let queryStr = "?" + queryString.stringify(query);
    this.setState({ sort: sort });
    this.setState({ sortBy: sortBy });
    this.refreshList(queryStr, defaultPage);
  };

  sortPrice = () => {
    let sort = "";
    if (this.state.sortBy === "price") {
      if (this.state.sort !== "desc") {
        sort = "desc";
      } else {
        sort = "asc";
      }
    } else {
      sort = "desc";
    }
    let sortBy = "price";
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    query.sort = sort;
    query.sortBy = sortBy;
    let queryStr = "?" + queryString.stringify(query);

    this.setState({ sort: sort });
    this.setState({ sortBy: sortBy });
    this.refreshList(queryStr, defaultPage);
  };

  sortName = () => {
    let sort = "";
    if (this.state.sortBy === "name") {
      if (this.state.sort !== "desc") {
        sort = "desc";
      } else {
        sort = "asc";
      }
    } else {
      sort = "desc";
    }
    let sortBy = "name";
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    query.sort = sort;
    query.sortBy = sortBy;
    let queryStr = "?" + queryString.stringify(query);

    this.setState({ sort: sort });
    this.setState({ sortBy: sortBy });
    this.refreshList(queryStr, defaultPage);
  };

  sortItemCode = () => {
    let sort = "";
    if (this.state.sortBy === "itemCode") {
      if (this.state.sort !== "desc") {
        sort = "desc";
      } else {
        sort = "asc";
      }
    } else {
      sort = "desc";
    }
    let sortBy = "itemCode";
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    query.sort = sort;
    query.sortBy = sortBy;
    let queryStr = "?" + queryString.stringify(query);

    this.setState({ sort: sort });
    this.setState({ sortBy: sortBy });
    this.refreshList(queryStr, defaultPage);
  };

  handleSelectProduct = (e) => {
    const userData = JSON.parse(getSession("userData"));
    const defaultPage = JSON.parse(getSession("defaultPage"));

    if (userData) {
      const sessionToken = userData.sessionToken;
      const { value: productId } = e;
      this.props.getProductById(productId, sessionToken, (err, res) => {
        if (err) {
          const { response } = err;
          let msg = "";
          if (typeof response === "string") msg = response;
          else {
            if (
              response.data !== null &&
              response.data.message !== null &&
              typeof response.data.message === "string"
            ) {
              msg = response.data.message;
            }
          }
          this.showNotificationError(msg);
        } else {
          const product = res;
          if (e && e.value) {
            let unit = product.unit;
            if (!unit || unit === "")
              unit = product.kind && product.kind === "crops" ? "kg." : "pc.";

            const prod = {
              id: product.id,
              name: product.name,
              kind: product.kind,
              unit: unit,
              reorderPoint: product.reorderPoint,
              place: defaultPage,
              volume: null,
            };

            this.setState({
              selectedProduct: product,
              reject: {
                product: prod,
              },
              user: userData.userId,
            });
          }
        }
      });
    }
  };

  handleChangeReject = (e) => {
    let { name, value } = e.target;
    if (name === "volumeRejected") {
      const intValue = !isNaN(parseInt(value)) ? parseInt(value) : 0;
      this.setState({
        reject: {
          ...this.state.reject,
          [name]: intValue,
        },
      });
    } else {
      this.setState({
        reject: {
          ...this.state.reject,
          [name]: value,
        },
      });
    }
  };
  handleSelectVolume = (e) => {
    let { value } = e.target;

    const userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;
    const selectedProduct = this.state.selectedProduct;

    if (selectedProduct !== null && selectedProduct.volumes !== null) {
      const volume = selectedProduct.volumes.find((i) => i.id === value);

      if (
        this.state.selectedProduct.id &&
        this.state.selectedProduct.kind &&
        this.state.selectedProduct.variations.id &&
        volume.id
      ) {
        // Update the `reject.product.volume` with the selected volume

        let queryStr = `?kind=${this.state.addedProduct.kind}&variation=${this.state.addedProduct.variation.id}&volume=${volume.id}`;
        const query = queryString.parse(queryStr);
        this.props.getProductStock(
          this.state.addedProduct.id,
          query,
          sessionToken,
          (_, res) => {
            if (res && res.id) {
              const { product } = this.state.reject;
              product["volumes"] = volume;
              this.setState((prevState) => ({
                reject: {
                  ...prevState.reject,
                  product: {
                    ...prevState.reject.product,
                    volume: volume, // Set the selected volume
                  },
                },
              }));
            } else {
              this.showNotificationError(
                "Could not find the specified stock. Please select a product with existing stocks."
              );
            }
          }
        );
      }
    }
  };
  handleSelectVolume = (e) => {
    let { value } = e.target;
    const userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;

    const selectedProduct = this.state.selectedProduct;
    if (selectedProduct !== null && selectedProduct.volumes !== null) {
      const volume = selectedProduct.volumes.find((i) => i.id === value);
      console.log(volume);
      console.log(selectedProduct);
      if (
        this.state.selectedProduct.id &&
        this.state.selectedProduct.kind &&
        this.state.selectedProduct.variations.id &&
        volume.id
      ) {
        let queryStr = `?kind=${this.state.addedProduct.kind}&variation=${this.state.addedProduct.variation.id}&volume=${volume.id}`;
        const query = queryString.parse(queryStr);
        this.props.getProductStock(
          this.state.addedProduct.id,
          query,
          sessionToken,
          (_, res) => {
            if (res && res.id) {
              const { product } = this.state.reject;
              product["volumes"] = volume;
              this.setState({
                reject: {
                  ...this.state.reject,
                  product,
                },
              });
            } else {
              this.showNotificationError(
                "Could not find the specified stock. Please select a product with existing stocks."
              );
            }
          }
        );
      }
    }
  };

  handleSelectVariation = (e) => {
    let { value } = e.target;
    const userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;

    const selectedProduct = this.state.selectedProduct;
    if (selectedProduct !== null && selectedProduct.variations !== null) {
      const variation = selectedProduct.variations.find((i) => i.id === value);
      if (
        this.state.selectedProduct.id &&
        this.state.selectedProduct.kind &&
        this.state.selectedProduct.volumes.id &&
        variation.id
      ) {
        let queryStr = `?kind=${this.state.addedProduct.kind}&variation=${this.state.addedProduct.volume.id}&volume=${variation.id}`;
        const query = queryString.parse(queryStr);
        this.props.getProductStock(
          this.state.addedProduct.id,
          query,
          sessionToken,
          (_, res) => {
            if (res && res.id) {
              const { product } = this.state.reject;
              product["variation"] = variation;
              this.setState({
                reject: {
                  ...this.state.reject,
                  product,
                },
              });
            } else {
              this.showNotificationError(
                "Could not find the specified stock. Please select a product with existing stocks."
              );
            }
          }
        );
      }
    }
  };

  handleSubmitReject = (e) => {
    e.preventDefault();
    const userData = JSON.parse(getSession("userData"));

    if (userData) {
      const sessionToken = userData.sessionToken;
      const reject = { ...this.state.reject };
      let hasError = false;
      if (
        reject.product.id &&
        reject.volumeRejected &&
        !isNaN(reject.volumeRejected) &&
        reject.volumeRejected >= 0
      ) {
        this.setState({ submitted: true });
        if (!hasError) {
          if (!window.confirm("Do you want to save this item?")) {
            return false;
          }
          this.setState({ isSaving: true });
          if (!reject.user) reject.user = userData.userId;

          this.props.addReject(reject, sessionToken, (err, res) => {
            if (res) {
              this.setState({ submitted: false, isSaving: false });
              if (res.id) {
                this.showNotification(
                  "Rejected details saved. Rejected item has been successfully logged"
                );
                this.toggleModal();

                let defaultPage = JSON.parse(getSession("defaultPage"));
                let url = this.props.location.search;
                let query = queryString.parse(url);
                delete query.message;
                delete query.page;
                let queryStr = "?" + queryString.stringify(query);
                this.refreshList(queryStr, defaultPage);
              }
            } else {
              if (err) {
                this.setState({ submitted: false, isSaving: false });
                const { response } = err;
                let msg = "";
                if (typeof response === "string") msg = response;
                else {
                  if (
                    response.data !== null &&
                    response.data.message !== null &&
                    typeof response.data.message === "string"
                  ) {
                    msg = response.data.message;
                  }
                }
                this.setState({ addRejectModalError: msg });
              } else {
                this.setState({
                  submitted: false,
                  isSaving: false,
                  addRejectModalError:
                    "An unknown error occured. Please try again.",
                });
              }
            }
          });
        }
      } else {
        this.setState({ submitted: true });
        this.showNotificationError(
          "Some fields are required. Please fill in the required fields"
        );
      }
    } else {
      this.props.history.push("/login");
      window.location.reload();
    }
  };

  handleViewReject = (e) => {
    const userData = JSON.parse(getSession("userData"));

    if (userData) {
      const sessionToken = userData.sessionToken;
      const rejectId = e.currentTarget.dataset.id;
      this.props.getRejectById(rejectId, sessionToken, (err, res) => {
        if (err) {
          const { response } = err;
          let msg = "";
          if (typeof response === "string") msg = response;
          else {
            if (
              response.data !== null &&
              response.data.message !== null &&
              typeof response.data.message === "string"
            ) {
              msg = response.data.message;
            }
          }
          this.showNotificationError(msg);
        } else {
          const reject = res;
          this.setState({
            modalView: true,
            selectedReject: reject,
          });
        }
      });
    }
  };

  refreshList(queryStr, pageId) {
    const query = queryString.parse(queryStr);
    const message = query.message;
    const checkAll = document.getElementById("check-all");
    if (checkAll) {
      checkAll.checked = false;
    }
    const checkboxes = document.querySelectorAll('input[name="item"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    if (message) {
      delete query.message;
      this.showNotification(message);
    }

    this.props.history.push("/product-markdown" + queryStr);
    const userData = JSON.parse(getSession("userData"));
    const pageInfo = JSON.parse(getSession("pageInfo"));
    const { sessionToken } = userData;
    if (userData !== null && pageInfo) {
      this.props.getMarkdownList(pageId, query, sessionToken, (err, res) => {
        if (!err && res) {
          this.setState({
            markdowns: res.docs,
            pagination: {
              limit: res.pageSize,
              total: res.totalRows,
            },
          });
        }
        this.setState({ isLoading: false });
      });
    } else {
      this.setState({ isLoading: false });
    }
  }
  exportfile(e) {
    e.preventDefault();
    const userData = JSON.parse(getSession("userData"));
    const pageInfo = JSON.parse(getSession("pageInfo"));
    let defaultPage = JSON.parse(getSession("defaultPage"));
    const startDate = this.state.startDateStr;
    const endDate = this.state.endDateStr;
    const { sessionToken } = userData;
    if (userData !== null && pageInfo) {
      let queryStr = `?place=${defaultPage}&dateStart=${startDate}&dateEnd=${endDate}`;
      const query = queryString.parse(queryStr);
      this.props.exportMarkdownList(query, sessionToken, (err, res) => {
        if (!err && res) {
          queryString.parse(
            `https://view.officeapps.live.com/op/view.aspx?src=${queryStr}&wdOrigin=BROWSELINK`
          );
        }
        this.setState({ isLoading: false });
      });
    } else {
      this.setState({ isLoading: false });
    }
  }
  handleChangeStartDate = (date) => {
    const formattedDateStart = format(date, "yyyy-MM-dd");
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    delete query.page;
    if (date !== "") {
      query.dateStart = formattedDateStart;
    } else {
      delete query.dateStart;
    }

    this.setState({ startDate: date });
    this.setState({ startDateStr: formattedDateStart });
    let queryStr = "?" + queryString.stringify(query);
    this.refreshList(queryStr, defaultPage);
  };

  handleChangeEndDate = (date) => {
    const formattedDateEnd = format(date, "yyyy-MM-dd");
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    delete query.page;
    if (date !== "") {
      query.dateEnd = formattedDateEnd;
    } else {
      delete query.dateEnd;
    }
    this.setState({ endDate: date });
    this.setState({ endDateStr: formattedDateEnd });
    let queryStr = "?" + queryString.stringify(query);
    this.refreshList(queryStr, defaultPage);
  };
  renderNoPageAdded() {
    return (
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref="notify" />
        </div>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Alert color="danger">
                  <h4 className="alert-heading">No Page Added</h4>
                  <hr />
                  <p className="mb-0">
                    You have not added a page yet. Click{" "}
                    <Link to="/add-page">here</Link> to add a new page.
                  </p>
                </Alert>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  renderAddModal() {
    return (
      <Modal
        isOpen={this.state.modal}
        toggle={this.toggleModal}
        backdrop="static"
        className={this.props.className}
        size="lg"
      >
        <div className="modal-header">
          <h4 className="modal-title text-medium">Add Reject Details</h4>
          <button
            type="button"
            className="close"
            onClick={this.toggleModal}
            aria-label="Close"
            style={{ color: "rgba(0, 0, 0, 0.6)" }}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <ModalBody>
          <form action="">
            <Row>
              <Col sm="12">
                {this.state.addRejectModalError && (
                  <Alert color="danger">{this.state.addRejectModalError}</Alert>
                )}
              </Col>
              <Col sm="12" md="6" lg="6">
                <FormGroup
                  className={
                    this.state.submittedModal &&
                    (!this.state.reject || !this.state.reject.id)
                      ? "has-danger"
                      : ""
                  }
                >
                  <Select
                    className="react-select"
                    options={this.state.inventoryProducts}
                    onChange={this.handleSelectProduct}
                    placeholder="Select a product"
                    value={this.state.inventoryProducts.filter(
                      (item) =>
                        item.value ===
                        (this.state.selectedProduct &&
                        this.state.selectedProduct.id
                          ? this.state.selectedProduct.id
                          : "")
                    )}
                  />
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="6">
                <FormGroup>
                  <p className="control-label">
                    Kind:&nbsp;
                    {this.state.reject &&
                    this.state.reject.product &&
                    this.state.reject.product.kind
                      ? capitalizeFirstLetter(this.state.reject.product.kind)
                      : "Goods"}
                  </p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="6" lg="6">
                <FormGroup
                  className={
                    this.state.submittedModal &&
                    (!this.state.reject ||
                      !this.state.reject.product ||
                      !this.state.reject.product.volume ||
                      !this.state.reject.product.volume.id)
                      ? " has-danger"
                      : ""
                  }
                >
                  <Label htmlFor="volume" className="control-label">
                    Volume
                  </Label>
                  <Input
                    id="volume"
                    name="volume"
                    className="volume"
                    placeholder="Volume"
                    type="select"
                    value={
                      this.state.reject &&
                      this.state.reject.product &&
                      this.state.reject.product.volume &&
                      this.state.reject.product.volume.id
                        ? this.state.reject.product.volume.id
                        : ""
                    }
                    onChange={this.handleSelectVolume}
                  >
                    <option value="">Select one</option>
                    {this.renderVolumes()}
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="6">
                <FormGroup
                  className={
                    this.state.submittedModal &&
                    (!this.state.reject ||
                      !this.state.reject.product ||
                      !this.state.reject.product.variations ||
                      !this.state.reject.product.variations.id)
                      ? " has-danger"
                      : ""
                  }
                >
                  <Label htmlFor="variation" className="control-label">
                    Variation
                  </Label>
                  <Input
                    id="variation"
                    name="variation"
                    className="variation"
                    placeholder="Variation"
                    type="select"
                    value={
                      this.state.reject &&
                      this.state.reject.product &&
                      this.state.reject.product.variations &&
                      this.state.reject.product.variations.id
                        ? this.state.reject.product.variations.id
                        : ""
                    }
                    onChange={this.handleSelectVariation}
                  >
                    <option value="">Select one</option>
                    {this.renderVariations()}
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="6">
                <FormGroup
                  className={
                    this.state.submittedModal &&
                    (!this.state.reject ||
                      !this.state.reject.volumeRejected ||
                      isNaN(this.state.reject.volumeRejected))
                      ? " has-danger"
                      : ""
                  }
                >
                  <Label htmlFor="volumeRejected" className="control-label">
                    Quantity
                  </Label>
                  <InputGroup>
                    <Input
                      id="volumeRejected"
                      name="volumeRejected"
                      className="volumeRejected"
                      placeholder="Qty."
                      type="text"
                      value={
                        this.state.reject && this.state.reject.volumeRejected
                          ? this.state.reject.volumeRejected
                          : 0
                      }
                      onChange={this.handleChangeReject}
                    />
                    <InputGroupText
                      className={
                        this.state.submittedModal &&
                        (!this.state.reject ||
                          !this.state.reject.volumeRejected ||
                          isNaN(this.state.reject.volumeRejected))
                          ? " has-danger"
                          : ""
                      }
                    >
                      {this.state.reject &&
                      this.state.reject.product &&
                      this.state.reject.product.unit
                        ? this.state.reject.product.unit
                        : "pc."}
                    </InputGroupText>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="remarks" className="control-label">
                    Remarks
                  </Label>
                  <Input
                    id="remarks"
                    name="remarks"
                    placeholder="Remarks"
                    type="textarea"
                    value={
                      this.state.reject && this.state.reject.remarks
                        ? this.state.reject.remarks
                        : ""
                    }
                    onChange={this.handleChangeReject}
                    style={{ height: "150px" }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={this.handleSubmitReject}>
            Save Entry
          </Button>{" "}
          <Button color="secondary" onClick={this.toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  renderViewModal(reject) {
    if (!reject || !reject.id) {
      return (
        <>
          <ModalBody>
            <Row>
              <Col sm="12" md="6" lg="6">
                <h4 className="alert-heading">Reject details not found </h4>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleViewModal}>
              <Fa icon="times" />
              &nbsp; Close
            </Button>
          </ModalFooter>
        </>
      );
    } else {
      return (
        <>
          <ModalBody>
            <Row>
              <Col sm="12" md="6" lg="6" className="mt-5">
                <p>
                  <span className="text-medium text-uppercase">
                    Date of Entry:{" "}
                  </span>
                  <span className="pull-right text-primary">
                    {format(new Date(reject.createdAt), "yyyy-MM-dd, hh:mm a")}
                  </span>
                </p>
                <p>
                  <span className="text-medium text-uppercase">
                    Item Name:{" "}
                  </span>
                  <span className="pull-right text-primary">
                    {reject.product.name}
                  </span>
                </p>
                <p>
                  <span className="text-medium text-uppercase">
                    Variation:{" "}
                  </span>
                  <span className="pull-right text-primary">
                    {reject.product.variation.description}
                  </span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="6" lg="6" className="mt-3">
                <p>
                  <span className="text-medium text-uppercase">
                    Cost per Unit:{" "}
                  </span>
                  <span className="pull-right text-primary">
                    &#8369;
                    {numberWithCommas(reject.amount / reject.volumeRejected)}
                  </span>
                </p>
                <p>
                  <span className="text-medium text-uppercase">
                    Volume Rejected:{" "}
                  </span>
                  <span className="pull-right text-primary">
                    {numberWithCommasOnly(reject.volumeRejected)}{" "}
                    {reject.product.unit ? reject.product.unit : "pc(s)."}
                  </span>
                </p>
                <p>
                  <span className="text-medium text-uppercase">
                    Total Loss Cost:{" "}
                  </span>
                  <span className="pull-right text-primary">
                    &#8369;{numberWithCommas(reject.amount)}
                  </span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="mt3">
                <p className="text-medium text-uppercase">Remarks:</p>
                <p className="text-primary">{reject.remarks}</p>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="6" lg="6" className="mt-3">
                <p>
                  <span className="text-medium text-uppercase">
                    Entered by:{" "}
                  </span>
                  <span className="text-primary">
                    {reject.user && reject.user.firstName
                      ? reject.user.firstName
                      : ""}{" "}
                    {reject.user && reject.user.lastName
                      ? reject.user.lastName
                      : ""}
                  </span>
                </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleViewModal}>
              <Fa icon="times" />
              &nbsp; Close
            </Button>
          </ModalFooter>
        </>
      );
    }
  }

  render() {
    const pageTitle = "Mark Down";
    let pageInfo = JSON.parse(getSession("pageInfo"));
    if (pageInfo) {
      if (pageInfo.pageType === "5dea2304f6bba08323a3ddce") {
        this.props.history.push("/tours");
        window.location.reload();
      }
    }
    if (pageInfo && pageInfo._id) {
      if (pageInfo.isVerified === true) {
        return (
          <>
            <div className="content">
              <div className="react-notification-alert-container">
                <NotificationAlert ref="notify" />
              </div>
              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <h4 className="title">
                        {pageTitle} - <em>{pageInfo.name}</em>
                      </h4>
                      <Col className="pr-md-1" md="6">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label
                                htmlFor="keyword"
                                className="control-label"
                                style={{ display: "block" }}
                              >
                                Search:
                              </label>

                              <FormGroup>
                                <Input
                                  id="keyword"
                                  name="keyword"
                                  type="text"
                                  placeholder="Search item..."
                                  onChange={this.handleChangeKeyword}
                                  onKeyPress={this.handleEnter}
                                  value={this.state.keyword}
                                ></Input>
                              </FormGroup>
                            </FormGroup>
                          </Col>

                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <label
                                  htmlFor="startDate"
                                  className="control-label"
                                  style={{ display: "block" }}
                                >
                                  Start Date:
                                </label>

                                <DatePicker
                                  name="startDate"
                                  className="form-control"
                                  selectsStart
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  selected={this.state.startDate}
                                  onChange={this.handleChangeStartDate}
                                  dateFormat="MMMM d, yyyy"
                                  autoComplete="off"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <label
                                  htmlFor="endDate"
                                  className="control-label"
                                  style={{ display: "block" }}
                                >
                                  End Date:
                                </label>

                                <DatePicker
                                  name="endDate"
                                  selectsEnd
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  className="form-control"
                                  selected={this.state.endDate}
                                  onChange={this.handleChangeEndDate}
                                  dateFormat="MMMM d, yyyy"
                                  autoComplete="off"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Row>{" "}
                      </Col>
                      <Col
                        style={{ textAlign: "right" }}
                        md="12"
                        className="pull-right"
                      >
                        <Link
                          to="#"
                          onClick={this.exportfile.bind(this)}
                          className="btn btn-round btn-info btn-sm"
                        >
                          <Fa icon="print" /> &nbsp;Print Report
                        </Link>
                        <Link
                          to="/product-markdown/new"
                          className="btn btn-round btn-info btn-sm"
                        >
                          <Fa icon="plus" />
                          &nbsp;Add New
                        </Link>
                      </Col>
                    </CardHeader>
                    <CardBody>
                      <Table className="tablesorter table-striped" responsive>
                        <thead className="text-primary">
                          <tr>
                            <th style={{ whiteSpace: "nowrap" }}>
                              Date Created
                            </th>
                            <th style={{ whiteSpace: "nowrap" }}>
                              Product Name
                            </th>

                            <th style={{ whiteSpace: "nowrap" }}>
                              {" "}
                              Effectivity Date
                            </th>
                            <th style={{ whiteSpace: "nowrap" }}>Created By</th>

                            <th style={{ whiteSpace: "nowrap" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>{this.renderRows(this.state.markdowns)}</tbody>
                      </Table>
                    </CardBody>
                    <CardFooter>
                      <Row>
                        <Col md="12">
                          <Row>
                            <Col md="12" lg="12">
                              {this.state.pagination &&
                                this.state.pagination.total > 0 && (
                                  <>
                                    <Pagination
                                      innerClass="pagination"
                                      activePage={this.state.activePage}
                                      itemsCountPerPage={
                                        this.state.pagination.limit
                                      }
                                      totalItemsCount={
                                        this.state.pagination.total
                                      }
                                      pageRangeDisplayed={5}
                                      onChange={this.handlePageChange}
                                    />
                                    <p>
                                      Page <em>{this.state.activePage}</em> of{" "}
                                      <em>
                                        {Math.ceil(
                                          this.state.pagination.total /
                                            this.state.pagination.limit
                                        )}
                                      </em>{" "}
                                      of{" "}
                                      <em>
                                        {numberWithCommasOnly(
                                          this.state.pagination.total
                                        )}
                                      </em>{" "}
                                      items.
                                    </p>
                                    <Input
                                      style={{
                                        marginBottom: "5px",
                                        width: "auto",
                                      }}
                                      id="perPage"
                                      name="perPage"
                                      type="select"
                                      onChange={this.handleChangePerPage}
                                      value={this.state.size}
                                    >
                                      <option value="10">10 / page</option>
                                      <option value="25">25 / page</option>
                                      <option value="50">50 / page</option>
                                      <option value="100">100 / page</option>
                                    </Input>
                                  </>
                                )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </div>
            <LoadingOverlay
              active={this.state.isProcessing}
              spinner
              text="Processing..."
            ></LoadingOverlay>
            <LoadingOverlay
              active={this.state.isGeneratingXls}
              spinner
              text="Processing..."
            ></LoadingOverlay>
            <div className="modal-section">
              {this.renderAddModal()}
              {/* View Details Modal */}
              <Modal
                isOpen={this.state.modalView}
                toggle={this.toggleViewModal}
                backdrop="static"
                className={this.props.className}
                size="lg"
              >
                <div className="modal-header">
                  <h4 className="modal-title text-medium">Reject Details</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={this.toggleViewModal}
                    aria-label="Close"
                    style={{ color: "rgba(0, 0, 0, 0.6)" }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                {this.renderViewModal(this.state.selectedReject)}
              </Modal>
            </div>
          </>
        );
      } else {
        return this.renderPageNotVerified();
      }
    } else {
      return this.renderNoPageAdded();
    }
  }
}

const numberWithCommas = (x) => {
  return priceRound(x).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const numberWithCommasOnly = (x) => {
  return priceRound(x, 0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const priceRound = (price, dec) => {
  if (dec === undefined) {
    dec = 2;
  }
  if (price !== 0) {
    if (!price || isNaN(price)) {
      throw new Error("price is not a number" + price);
    }
  }
  const str = parseFloat(Math.round(price * 100) / 100).toFixed(dec);
  return str;
};

const capitalizeFirstLetter = (str) => {
  if (typeof str === "string" && str.length > 0) {
    return str.replace(/^./, str[0].toUpperCase());
  }
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  getInventory,
  getProductById,
  getMarkdownList,
  addReject,
  getRejectById,
  updateReject,
  deletemarkdown,
  exportMarkdownList,
})(ProductMarkdown);
