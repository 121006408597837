import React from "react";
import { connect } from "react-redux";
import { getSession } from "../../config/session";
// import api from "../../config/api";
import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";

import {
  getProductCategoriesAllV2,
  addProductCategory,
  uploadPhoto,
  removePhoto,
} from "../../layouts/Admin/actions/ProductCategoryActions";
import {
  getProductById,
  getInventory,
  getStocks,
  addReconciliation,
} from "../../layouts/Admin/actions/InventoryActions";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Table,
  Row,
  Col,
  Input,
  Label,
  Badge,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import queryString from "query-string";

class AddReconciliation extends React.Component {
  constructor(props) {
    super(props);
    const dateToday = format(new Date(), "MMMM d, yyyy");

    const defaultPage = JSON.parse(getSession("defaultPage"));

    const userData = JSON.parse(getSession("userData"));
    let userInfo = {};
    if (userData != null) {
      userInfo = userData.info;
    }
    this.state = {
      todayDate: dateToday,

      reconciliation: {
        products: [],
        reconciliationDate: "",
        description: "",
        status: "unpublished",
        preparedBy: userData && userData.userId ? userData.userId : "",
        place: defaultPage,
        pickedDate: "",
        totalVarianceByUnit: [],
      },
      inventoryProducts: [],
      selectedProduct: null,
      addedProduct: null,
      isLoading: false,
      isSaving: false,
      submitted: false,
      hideTutorials: false,
      addProductModalError: "",
      submittedModal: false,
      modal: false,
      user: {
        name: userInfo.firstName + " " + userInfo.lastName,
      },
    };
  }

  componentDidMount() {
    const userData = JSON.parse(getSession("userData"));

    if (userData == null) {
      this.props.history.push("/login");
      window.location.reload();
    }

    const sessionToken = userData.sessionToken;
    const pageInfo = JSON.parse(getSession("pageInfo"));
    const pageId = pageInfo && pageInfo._id ? pageInfo._id : "";
    let url = this.props.location.search;
    let query = queryString.parse(url);
    let queryStr = "?" + queryString.stringify(query);

    this.props.getStocks(pageId, queryStr, sessionToken, (err, res) => {
      if (!err && res) {
        if (res.docs && res.docs instanceof Array && res.docs.length > 0) {
          let products = [];
          res.docs.forEach((item) => {
            const productItem = {
              value: item.id,

              stockCount: item.stockCount,
              actualStockCount: item.actualStockCount,
              product: item.product,
            };
            products.push(productItem);
          });
          this.setState({ inventoryProducts: products });
        }
      }
    });
  }

  showNotification(message) {
    if (message) {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "success",
        icon: "",
        autoDismiss: 5,
      };
      this.refs.notify.notificationAlert(notification);
    }
  }

  showNotificationError(message) {
    if (message) {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "danger",
        icon: "",
        autoDismiss: 5,
      };
      this.refs.notify.notificationAlert(notification);
    }
  }

  handleChangeAddProduct = (e) => {
    let { name, value } = e.target;

    this.setState({
      addedProduct: {
        ...this.state.addedProduct,
        [name]: value,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const userData = JSON.parse(getSession("userData"));

    if (userData) {
      const sessionToken = userData.sessionToken;
      const products = this.state.inventoryProducts.map((item) => ({
        id: item.product.id,
        name: item.product.name,
        kind: item.product.kind,
        unit: item.product.unit,
        volume: {
          id: item.product.volume && item.product.volume.id,
          description: item.product.volume && item.product.volume.description,
          value: item.product.volume && item.product.volume.value,
        },
        variation: {
          id: item.product.variation && item.product.variation.id,
          description:
            item.product.variation && item.product.variation.description,
        },
        stockCount: item.stockCount,
        physicalCount: !isNaN(parseInt(item.physicalCount))
          ? parseInt(item.physicalCount)
          : 0,
        remarks: item.remarks,
      }));
      const reconciliation = {
        ...this.state.reconciliation,
        products: products,
      };
      let hasError = false;
      if (reconciliation) {
        this.setState({ submitted: true });
        if (
          !reconciliation.reconciliationDate ||
          products.some((p) => !p.remarks || p.remarks.trim() === "")
        ) {
          hasError = true;
          this.showNotificationError(
            "Some fields are required. Please fill in the required fields"
          );
        }

        // if (products.some((p) => p.physicalCount === 0)) {
        //   hasError = true;
        //   this.showNotificationError(
        //     "Physical count should not be 0 for any product"
        //   );
        // }
        if (!hasError) {
          if (!window.confirm("Do you want to save this item?")) {
            return false;
          }
          this.setState({ isSaving: true });

          this.props.addReconciliation(
            reconciliation,
            sessionToken,
            (err, res) => {
              if (res) {
                this.setState({ submitted: false, isSaving: false });
                if (res.id) {
                  this.showNotification("Reconciliation has been added");
                  setTimeout(() => {
                    this.props.history.push("/inventory-reconciliation");
                  }, 3000);
                }
              } else {
                if (err) {
                  this.setState({ submitted: false, isSaving: false });
                  const { response } = err;
                  let msg = "";
                  if (typeof response === "string") msg = response;
                  else {
                    if (
                      response.data !== null &&
                      response.data.message !== null &&
                      typeof response.data.message === "string"
                    ) {
                      msg = response.data.message;
                    }
                  }
                  this.setState({ addSupplierModalError: msg });
                } else {
                  this.setState({
                    submitted: false,
                    isSaving: false,
                    addSupplierModalError:
                      "An unknown error occured. Please try again.",
                  });
                }
              }
            }
          );
        }
      } else {
        this.setState({ submitted: true });
        this.showNotificationError(
          "Some fields are required. Please fill in the required fields"
        );
      }
    } else {
      this.props.history.push("/login");
      window.location.reload();
    }
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  handleChangeProduct = (e) => {
    const { name, value } = e.target;
    if (name === "description") {
      this.setState({
        reconciliation: {
          ...this.state.reconciliation,
          description: value,
        },
      });
      return;
    }

    const index = e.currentTarget.dataset.idx;

    if (index !== null) {
      let inventoryProducts = [...this.state.inventoryProducts];

      const parsedValue =
        name === "physicalCount" ? parseFloat(value) || 0 : value;

      inventoryProducts[index] = {
        ...inventoryProducts[index],
        [name]: parsedValue,
      };

      // Calculate variance if physicalCount is changed
      if (name === "physicalCount") {
        const variance = inventoryProducts[index].stockCount - parsedValue;
        inventoryProducts[index].variance = variance;
      }

      // Calculate total variance by unit type
      const totalVarianceByUnit = inventoryProducts.reduce((acc, product) => {
        const volumeType = (product.product && product.product.unit) || "";
        const varianceValue = product.variance || 0;

        if (!acc[volumeType]) {
          acc[volumeType] = 0;
        }

        acc[volumeType] += varianceValue;
        return acc;
      }, {});

      // Update state
      this.setState({
        inventoryProducts,
        reconciliation: {
          ...this.state.reconciliation,
          totalVarianceByUnit: totalVarianceByUnit,
        },
      });
    }
  };

  // handleChangeProduct = (e) => {
  //   const { name, value } = e.target;
  //   const index = e.currentTarget.dataset.idx;

  //   if (index !== null) {
  //     let inventoryProducts = [...this.state.inventoryProducts];

  //     const parsedValue =
  //       name === "physicalCount" ? parseFloat(value) || 0 : value;

  //     inventoryProducts[index] = {
  //       ...inventoryProducts[index],
  //       [name]: parsedValue,
  //     };
  //     if (name === "physicalCount") {
  //       const variance = parsedValue - inventoryProducts[index].stockCount;
  //       inventoryProducts[index].variance = variance;
  //     }
  //     // const totalVariance = inventoryProducts.reduce((acc, product) => {
  //     //   return acc + (product.variance || 0);
  //     // }, 0);
  //     const totalVarianceByUnit = inventoryProducts.reduce((acc, product) => {
  //       const volumeType = (product.product && product.product.unit) || ""; // Assuming there's a 'unit' property for volume type
  //       const varianceValue = product.variance || 0;

  //       // Initialize the volume type in the accumulator if it doesn't exist
  //       if (!acc[volumeType]) {
  //         acc[volumeType] = 0;
  //       }

  //       // Accumulate the variances by volume type
  //       acc[volumeType] += varianceValue;

  //       return acc;
  //     }, {});
  //     this.setState({
  //       inventoryProducts,
  //       reconciliation: {
  //         ...this.state.reconciliation,
  //         totalVarianceByUnit: totalVarianceByUnit,
  //         // totalVarianceByVolume: totalVariance,
  //       },
  //     });
  //   } else if (name === "description") {
  //     this.setState({
  //       reconciliation: {
  //         ...this.state.reconciliation,
  //         [name]: value,
  //       },
  //     });
  //   }
  // };

  handleDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

    this.setState({
      reconciliation: {
        ...this.state.reconciliation,
        pickedDate: date,
        reconciliationDate: formattedDate,
      },
    });
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    this.setState({
      reconciliation: {
        ...this.state.reconciliation,
        description: value,
      },
    });
  };
  renderRows(inventoryProducts) {
    if (this.state.isLoading) {
      return (
        <tr>
          <td colSpan={7}>
            <PulseLoader
              sizeUnit={"px"}
              size={15}
              color={"#1d8cf8"}
              loading={this.state.isLoading}
            />
          </td>
        </tr>
      );
    } else {
      if (inventoryProducts instanceof Array && inventoryProducts.length > 0) {
        return inventoryProducts.map((item, index) => (
          <tr key={index}>
            <td className="text-medium">{item.product.name}</td>
            <td className="text-medium">
              {item.product.variation.description}
            </td>
            <td className="text-medium">{item.product.volume.description}</td>
            <td>{item.stockCount}</td>
            <td>
              <FormGroup
              // className={
              //   (this.state.submitted &&
              //     !this.state.inventoryProducts[index].physicalCount) ||
              //   this.state.inventoryProducts[index].physicalCount === 0
              //     ? " has-danger"
              //     : ""
              // }
              >
                <InputGroup>
                  <Input
                    name="physicalCount"
                    placeholder="Physical Count"
                    type="text"
                    value={
                      this.state.inventoryProducts[index].physicalCount || 0
                    }
                    data-idx={index}
                    onChange={this.handleChangeProduct}
                  />
                  <InputGroupText
                  // className={
                  //   (this.state.submitted &&
                  //     !this.state.inventoryProducts[index].physicalCount) ||
                  //   this.state.inventoryProducts[index].physicalCount === 0
                  //     ? " has-danger"
                  //     : ""
                  // }
                  >
                    {this.state.inventoryProducts[index].product.unit || ""}
                  </InputGroupText>
                </InputGroup>
              </FormGroup>
            </td>

            <td width="200">
              <FormGroup>
                <Input
                  name="variance"
                  readOnly
                  placeholder="Variance"
                  type="number"
                  value={this.state.inventoryProducts[index].variance || 0}
                  data-idx={index}
                />
              </FormGroup>
            </td>
            <td width="300">
              <FormGroup
                className={
                  this.state.submitted &&
                  !this.state.inventoryProducts[index].remarks
                    ? " has-danger"
                    : ""
                }
              >
                <Input
                  name="remarks"
                  placeholder="Remarks"
                  type="text"
                  value={this.state.inventoryProducts[index].remarks || ""}
                  data-idx={index}
                  onChange={this.handleChangeProduct}
                />
              </FormGroup>
            </td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={7}>
              <h5 className="text-danger">
                <em>No item(s) found.</em>
              </h5>
            </td>
          </tr>
        );
      }
    }
  }

  renderReconciliationForm(reconciliation) {
    // let { submitted } = this.state;
    return (
      <>
        {" "}
        <Row>
          <Col sm="12">
            <Row>
              <Col lg="12" md="12" sm="12">
                <FormGroup>
                  <Alert color="primary">Add reconciliation details.</Alert>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="3" md="3" sm="6">
                <FormGroup>
                  <label htmlFor="slug" className="control-label">
                    Date Created
                    <em className="text-muted"></em>
                  </label>
                  <Input
                    readOnly
                    name="createdDate"
                    className="createdDate"
                    placeholder="Date Created"
                    type="text"
                    defaultValue={this.state.todayDate}
                  />
                </FormGroup>
              </Col>{" "}
              <Col lg="3" md="3" sm="6">
                <FormGroup
                  className={
                    this.state.submitted && !reconciliation.reconciliationDate
                      ? " has-danger"
                      : ""
                  }
                >
                  <label htmlFor="name" className="control-label">
                    As of <em className="text-muted">(Required)</em>
                  </label>
                  <DatePicker
                    selected={reconciliation.pickedDate}
                    onChange={this.handleDateChange}
                    dateFormat="MMMM d, yyyy"
                    className="form-control"
                    placeholderText="Select date"
                  />
                </FormGroup>
              </Col>
              <Col lg="3" md="3" sm="6">
                <FormGroup>
                  <label htmlFor="slug" className="control-label">
                    Prepared By
                    <em className="text-muted"></em>
                  </label>
                  <Input
                    readOnly
                    name="time"
                    className="time"
                    placeholder="Time"
                    type="text"
                    defaultValue={this.state.user.name}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Label htmlFor="description" className="control-label">
                    Description
                  </Label>
                  <Input
                    id="description"
                    name="description"
                    placeholder="Description"
                    type="textarea"
                    value={this.state.reconciliation.description} // Ensure value comes from the correct state
                    onChange={this.handleChange}
                    style={{ height: "100px" }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <p className="control-label">Product</p>
                  <Row>
                    <Col>
                      <Table
                        style={{ minWidth: "600px" }}
                        className="tablesorter table-hover"
                        responsive
                        size="sm"
                      >
                        <thead className="text-primary">
                          <tr>
                            <th>Product Name</th>
                            <th>Variation</th>

                            <th>Volume</th>
                            <th>Stock Count</th>
                            <th>Physical Count</th>
                            <th>Difference/Variance</th>
                            <th>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.renderRows(this.state.inventoryProducts)}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <br />
                  <Col md="12">
                    <Row className="pull-right">
                      <Label htmlFor="productType" className="control-label">
                        TOTAL VARIANCE:&nbsp;
                      </Label>
                      <span align="center">
                        {Object.entries(
                          this.state.reconciliation.totalVarianceByUnit || []
                        ).map(([volumeType, totalVariance]) => (
                          <span align="center">
                            <Badge color="primary" pill>
                              {totalVariance} {volumeType}
                            </Badge>{" "}
                          </span>
                        ))}
                      </span>
                    </Row>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
  render() {
    const pageInfo = JSON.parse(getSession("pageInfo"));
    if (pageInfo && pageInfo._id) {
      return (
        <>
          <div className="content">
            <div className="react-notification-alert-container">
              <NotificationAlert ref="notify" />
            </div>
            <Row>
              <Col sm="12" md="12" lg="12">
                <Card>
                  <Form
                    onSubmit={this.handleSubmit}
                    onKeyPress={this.onKeyPress}
                  >
                    <CardHeader>
                      <h4 className="title">Add New Reconciliation</h4>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          {this.renderReconciliationForm(
                            this.state.reconciliation
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="pull-right">
                      <Button type="submit" className="btn-round" color="info">
                        Save
                      </Button>
                      <Link
                        to="/inventory-reconciliation"
                        className="btn btn-round btn-light"
                      >
                        Cancel
                      </Link>
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            </Row>
            <LoadingOverlay
              active={this.state.isSaving}
              spinner
              text="Saving..."
            ></LoadingOverlay>
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  getProductCategoriesAllV2,
  addProductCategory,
  uploadPhoto,
  removePhoto,
  getProductById,
  getInventory,
  getStocks,
  addReconciliation,
})(AddReconciliation);
