import {
  GET_INVENTORY,
  GET_PURCHASE_ENTRY,
  GET_PURCHASE_ENTRIES,
  GET_PRODUCTS,
  GET_SUPPLIERS,
  GET_PURCHASERS,
} from "../../../reducers/products/types";

import api from "../../../config/api";
import apiV2 from "../../../config/apiV2";
export const getPurchasesByPageId =
  (placeId, query, token, cb) => (dispatch, _) => {
    apiV2(token)
      .get(`inventory/merchants/${placeId}/purchase-entries/`, {
        params: query,
      })
      .then((resp) => {
        if (resp && resp.data) {
          dispatch({
            type: GET_PRODUCTS,
            payload: resp.data,
          });
          return cb(null, resp.data);
        }
        cb(resp, null);
      })
      .catch((error) => {
        cb(error, null);
      });
  };

export const getPurchaseById = (purchaseId, token, cb) => (dispatch, _) => {
  apiV2(token)
    .get("/inventory/purchase-entries/" + purchaseId)
    .then((resp) => {
      if (resp && resp.data) {
        dispatch({
          type: GET_PURCHASE_ENTRY,
          payload: resp.data,
        });
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const addPurchase = (purchase, token, cb) => () => {
  const body = purchase;
  apiV2(token)
    .post("/inventory/purchase-entries", body)
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const updatePurchase = (purchaseId, purchase, token, cb) => () => {
  const body = purchase;
  apiV2(token)
    .patch(`/inventory/purchase-entries/${purchaseId}`, body)
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const deletePurchase = (purchaseId, token, cb) => () => {
  apiV2(token)
    .delete(`/inventory/purchase-entries/${purchaseId}`)
    .then((resp) => {
      cb(null, resp);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const getPurchaseEntriesList = (query, token, cb) => () => {
  apiV2(token)
    .get("/inventory/purchase-entries/list", { params: query })
    .then((resp) => {
      if (resp) {
        return cb(null, resp);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const getPurchaseEntriesListXls = (query, token, cb) => () => {
  apiV2(token)
    .get("/inventory/purchase-entries/list-xls", { params: query })
    .then((response) => {
      if (response) {
        return cb(null, response);
      }
      cb(response, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const getPurchaseEntries =
  (placeId, query, token, cb) => (dispatch, _) => {
    apiV2(token)
      .get(`inventory/places/${placeId}/purchase-entries`, { params: query })
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: GET_PURCHASE_ENTRIES,
            payload: response.data,
          });
          return cb(null, response.data);
        }
        cb(response, null);
      })
      .catch((error) => {
        cb(error, null);
      });
  };

export const getInventory = (placeId, query, token, cb) => (dispatch, _) => {
  apiV2(token)
    .get(`/inventory/places/${placeId}/products`, { params: query })
    .then((resp) => {
      if (resp && resp.data) {
        dispatch({
          type: GET_INVENTORY,
          payload: resp.data,
        });
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((err) => {
      cb(err, null);
    });
};

export const getStocks = (placeId, query, token, cb) => () => {
  apiV2(token)
    .get(`/inventory/places/${placeId}/stock-counts`, { params: query })
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((err) => {
      cb(err, null);
    });
};

export const getProductStock = (productId, query, token, cb) => () => {
  apiV2(token)
    .get(
      "/inventory/products/" + productId + "/stock-counts/get-one-by-query",
      { params: query }
    )
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((err) => {
      cb(err, null);
    });
};
export const getProductStockLogs = (query, token, cb) => () => {
  apiV2(token)
    .get("/inventory/products-stock-logs-by-variation-and-volume", {
      params: query,
    })
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((err) => {
      cb(err, null);
    });
};
export const getProductStockLogsByProduct = (id, query, token, cb) => () => {
  apiV2(token)
    .get(`inventory/products/${id}/stock-logs-by-query`, {
      params: query,
    })
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((err) => {
      cb(err, null);
    });
};
export const getCostLogsByProduct = (productId, query, token, cb) => () => {
  apiV2(token)
    .get("/inventory/products/" + productId + "/cost-logs", { params: query })
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((err) => {
      cb(err, null);
    });
};
export const getPriceLogsByQuery = (query, token, cb) => () => {
  apiV2(token)
    .get("inventory/product-price-logs-by-variation-and-volume", {
      params: query,
    })
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((err) => {
      cb(err, null);
    });
};
export const getPriceLogsByProduct = (query, token, cb) => () => {
  apiV2(token)
    .get("inventory/product-price-log-by-query", {
      params: query,
    })
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((err) => {
      cb(err, null);
    });
};
export const getProductById = (productId, token, cb) => () => {
  apiV2(token)
    .get("/inventory/products/" + productId)
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((err) => {
      cb(err, null);
    });
};

export const getRejects = (placeId, query, token, cb) => () => {
  apiV2(token)
    .get(`/inventory/places/${placeId}/rejects`, { params: query })
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((err) => {
      cb(err, null);
    });
};

export const addReject = (reject, token, cb) => () => {
  const body = reject;
  apiV2(token)
    .post("/inventory/rejects", body)
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const updateReject = (rejectId, reject, token, cb) => () => {
  const body = reject;
  apiV2(token)
    .patch(`/inventory/rejects/${rejectId}`, body)
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};
export const deleteReject = (rejectId, token, cb) => () => {
  apiV2(token)
    .delete(`/inventory/rejects/${rejectId}`)
    .then((resp) => {
      cb(null, resp);
    })
    .catch((error) => {
      cb(error, null);
    });
};
export const getRejectById = (rejectId, token, cb) => () => {
  apiV2(token)
    .get("/inventory/rejects/" + rejectId)
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const getSuppliers = (query, token, cb) => (dispatch, _) => {
  apiV2(token)
    .get("/inventory/suppliers", { params: query })
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: GET_SUPPLIERS,
          payload: res,
        });
        return cb(null, res.data);
      }
      cb(res, null);
    })
    .catch((err) => {
      //console.log(err);
      //  cb(err, null);
    });
};

export const deleteSupplier = (supplierId, token, cb) => () => {
  apiV2(token)
    .delete(`/inventory/suppliers/${supplierId}`)
    .then((resp) => {
      cb(null, resp);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const addSupplier = (supplier, token, cb) => () => {
  const body = supplier;
  apiV2(token)
    .post("/inventory/suppliers", body)
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const updateSupplier = (supplierId, supplier, token, cb) => () => {
  const body = supplier;
  apiV2(token)
    .patch(`/inventory/suppliers/${supplierId}`, body)
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((error) => {
      console.log(error);
      cb(error, null);
    });
};

export const getPurchasers = (query, token, cb) => (dispatch, _) => {
  apiV2(token)
    .get("/inventory/purchasers", { params: query })
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: GET_PURCHASERS,
          payload: res,
        });
        return cb(null, res.data);
      }
      cb(res, null);
    })
    .catch((err) => {
      cb(err, null);
    });
};

export const uploadReceiptPhoto = (data, token, cb) => () => {
  api(token)
    .post(`business/purchase-entries/receipt-photos`, data)
    .then((resp) => {
      if (resp && resp.data) return cb(null, resp.data);
    })
    .catch((err) => {
      cb(err, null);
    });
};

export const removeReceiptPhoto =
  (purchaseEntryId, photoId, token, cb) => () => {
    api(token)
      .delete(`business/products/${purchaseEntryId}/media/${photoId}`)
      .then((resp) => {
        if (resp && resp.data) return cb(null, resp.data);
      })
      .catch((error) => {
        cb(error, null);
      });
  };

export const getMarkdownList = (placeId, query, token, cb) => () => {
  apiV2(token)
    .get(`/inventory/places/${placeId}/mark-downs`, { params: query })
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((err) => {
      cb(err, null);
    });
};

export const addMarkdown = (reject, token, cb) => () => {
  const body = reject;
  apiV2(token)
    .post("/inventory/mark-downs", body)
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const updateMarkdown = (markdownId, markdown, token, cb) => () => {
  const body = markdown;
  apiV2(token)
    .patch(`/inventory/mark-downs/${markdownId}`, body)
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const getMarkdownbyid = (markdownId, token, cb) => () => {
  apiV2(token)
    .get("/inventory/mark-downs/" + markdownId)
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const deletemarkdown = (markdownId, token, cb) => () => {
  apiV2(token)
    .delete(`/inventory/mark-downs/${markdownId}`)
    .then((resp) => {
      cb(null, resp);
    })
    .catch((error) => {
      cb(error, null);
    });
};
export const getReconciliations = (placeId, query, token, cb) => () => {
  apiV2(token)
    .get(`/inventory/places/${placeId}/reconciliations`, { params: query })
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((err) => {
      cb(err, null);
    });
};
export const addReconciliation = (payload, token, cb) => () => {
  const body = payload;
  apiV2(token)
    .post("/inventory/reconciliations", body)
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const updateReconciliation = (id, payload, token, cb) => () => {
  const body = payload;
  apiV2(token)
    .patch(`/inventory/reconciliations/${id}`, body)
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const getReconciliationByid = (id, token, cb) => () => {
  apiV2(token)
    .get("/inventory/reconciliations/" + id)
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const deleteReconciliations = (id, token, cb) => () => {
  apiV2(token)
    .delete(`/inventory/reconciliations/${id}`)
    .then((resp) => {
      cb(null, resp);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const publishReconciliation = (id, payload, token, cb) => () => {
  const body = payload;
  apiV2(token)
    .post(`/inventory/reconciliations/${id}/publish`, body)
    .then((resp) => {
      if (resp) {
        return cb(null, resp);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const declineReconciliation = (id, payload, token, cb) => () => {
  const body = payload;
  apiV2(token)
    .post(`/inventory/reconciliations/${id}/decline`, body)
    .then((resp) => {
      if (resp) {
        return cb(null, resp);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

//REPORTS Exports

export const exportMarkdownList = (query, token, cb) => () => {
  apiV2(token)
    .get(`/inventory/mark-downs-xlsx`, {
      params: query,
      responseType: "blob", // Ensure we get a Blob back
    })
    .then((resp) => {
      if (resp && resp.status === 200 && resp.data) {
        const blob = new Blob([resp.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a URL for the Blob and trigger download
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        // link.download = `ExportMarkdown_${query.someIdentifier}.xlsx`; // Adjust filename as needed

        link.download = `ExportMarkdownFile.xlsx`; // Adjust filename as needed
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the object URL and removing the link element
        URL.revokeObjectURL(url);
        document.body.removeChild(link);

        // Callback with no error and the response data
        cb(null, resp.data);
      } else {
        // Callback with an error if the response is not as expected
        cb(new Error("Failed to download file"), null);
      }
    })
    .catch((err) => {
      cb(err, null);
    });
};
export const exportPurchaseList = (query, token, cb) => () => {
  apiV2(token)
    .get(`/inventory/purchase-entries-xlsx`, {
      params: query,
      responseType: "blob",
    })
    .then((resp) => {
      if (resp && resp.status === 200 && resp.data) {
        const blob = new Blob([resp.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a URL for the Blob and trigger download
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        // link.download = `ExportMarkdown_${query.someIdentifier}.xlsx`; // Adjust filename as needed

        link.download = `ExportPurchaseEntriesFile.xlsx`; // Adjust filename as needed
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the object URL and removing the link element
        URL.revokeObjectURL(url);
        document.body.removeChild(link);

        // Callback with no error and the response data
        cb(null, resp.data);
      } else {
        // Callback with an error if the response is not as expected
        cb(new Error("Failed to download file"), null);
      }
    })
    .catch((err) => {
      cb(err, null);
    });
};

export const exportPurchaseById = (id, token, cb) => () => {
  apiV2(token)
    .get(`/inventory/purchases/${id}/purchase-report-xlsx`, {
      responseType: "blob",
    })
    .then((resp) => {
      if (resp && resp.status === 200 && resp.data) {
        const blob = new Blob([resp.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a URL for the Blob and trigger download
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        // link.download = `ExportMarkdown_${query.someIdentifier}.xlsx`; // Adjust filename as needed

        link.download = `ExportPurchaseEntriesIndividualFile.xlsx`; // Adjust filename as needed
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the object URL and removing the link element
        URL.revokeObjectURL(url);
        document.body.removeChild(link);

        // Callback with no error and the response data
        cb(null, resp.data);
      } else {
        // Callback with an error if the response is not as expected
        cb(new Error("Failed to download file"), null);
      }
    })
    .catch((err) => {
      cb(err, null);
    });
};

export const exportRejectList = (query, token, cb) => () => {
  apiV2(token)
    .get(`/inventory/rejects-xlsx`, {
      params: query,
      responseType: "blob",
    })
    .then((resp) => {
      if (resp && resp.status === 200 && resp.data) {
        const blob = new Blob([resp.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a URL for the Blob and trigger download
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        // link.download = `ExportMarkdown_${query.someIdentifier}.xlsx`; // Adjust filename as needed

        link.download = `ExportRejectFile.xlsx`; // Adjust filename as needed
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the object URL and removing the link element
        URL.revokeObjectURL(url);
        document.body.removeChild(link);

        // Callback with no error and the response data
        cb(null, resp.data);
      } else {
        // Callback with an error if the response is not as expected
        cb(new Error("Failed to download file"), null);
      }
    })
    .catch((err) => {
      cb(err, null);
    });
};

export const exportReconciliationList = (query, token, cb) => () => {
  apiV2(token)
    .get(`/inventory/reconciliations-xlsx`, {
      params: query,
      responseType: "blob",
    })
    .then((resp) => {
      if (resp && resp.status === 200 && resp.data) {
        const blob = new Blob([resp.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a URL for the Blob and trigger download
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        // link.download = `ExportMarkdown_${query.someIdentifier}.xlsx`; // Adjust filename as needed

        link.download = `ExportReconciliationFile.xlsx`; // Adjust filename as needed
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the object URL and removing the link element
        URL.revokeObjectURL(url);
        document.body.removeChild(link);

        // Callback with no error and the response data
        cb(null, resp.data);
      } else {
        // Callback with an error if the response is not as expected
        cb(new Error("Failed to download file"), null);
      }
    })
    .catch((err) => {
      cb(err, null);
    });
};

export const exportStockCountList = (query, token, cb) => () => {
  apiV2(token)
    .get(`/inventory/stock-counts-xlsx`, {
      params: query,
      responseType: "blob",
    })
    .then((resp) => {
      if (resp && resp.status === 200 && resp.data) {
        const blob = new Blob([resp.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a URL for the Blob and trigger download
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        // link.download = `ExportMarkdown_${query.someIdentifier}.xlsx`; // Adjust filename as needed

        link.download = `ExportInventoryLevelFile.xlsx`; // Adjust filename as needed
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the object URL and removing the link element
        URL.revokeObjectURL(url);
        document.body.removeChild(link);

        // Callback with no error and the response data
        cb(null, resp.data);
      } else {
        // Callback with an error if the response is not as expected
        cb(new Error("Failed to download file"), null);
      }
    })
    .catch((err) => {
      cb(err, null);
    });
};
export const exportStocksbyProduct = (productId, query, token, cb) => () => {
  apiV2(token)
    .get(`/inventory/products/${productId}/stock-logs-xlsx`, {
      params: query,
      responseType: "blob",
    })
    .then((resp) => {
      if (resp && resp.status === 200 && resp.data) {
        const blob = new Blob([resp.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a URL for the Blob and trigger download
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        // link.download = `ExportMarkdown_${query.someIdentifier}.xlsx`; // Adjust filename as needed

        link.download = `ExportInventoryLevelStocksFile.xlsx`; // Adjust filename as needed
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the object URL and removing the link element
        URL.revokeObjectURL(url);
        document.body.removeChild(link);

        // Callback with no error and the response data
        cb(null, resp.data);
      } else {
        // Callback with an error if the response is not as expected
        cb(new Error("Failed to download file"), null);
      }
    })
    .catch((err) => {
      cb(err, null);
    });
};
