import React from "react";
import { connect } from "react-redux";
import { getSession, setSession } from "../../config/session";
import api from "../../config/api";
import { Link } from "react-router-dom";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import NotificationAlert from "react-notification-alert";
import LoadingOverlay from "react-loading-overlay";
import Switch from "react-toggle-switch";
import {
  getProductTypes,
  addProduct,
} from "../../layouts/Admin/actions/ProductActions";
import { SyncLoader } from "react-spinners";
import CheckboxTree from "react-checkbox-tree";
import { format } from "date-fns";
import DatePicker from "react-datepicker";

import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  InputGroup,
  InputGroupText,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Table,
} from "reactstrap";
const divStyle = {
  position: "relative",
  height: 50,
  width: 50,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderWidth: 2,
  borderColor: "#666",
  borderStyle: "solid",
  borderRadius: 5,
};
class AddRestoreProduct extends React.Component {
  constructor(props) {
    const defaultPage = JSON.parse(getSession("defaultPage"));
    const expirationDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
    super(props);
    this.state = {
      expirationDate: expirationDate,
      product: {
        name: "",
        description: "",
        productType: "",
        forSale: false,
        isActive: false,
        unit: "",
        limit: "",
        autofullfill: false,
        price: "",
        comparePrice: "",
        placeId: defaultPage,
      },
      productTypes: [],
      productCategories: [],
      disableBarcode: true,
      disableItemcode: true,
      places: [],
      checked: [],
      expanded: [],
      isLoading: false,
      submitted: false,
      submittedHighlight: false,
      isSaving: false,
      isGrocery: false,
      pageType: "food-drink",
      activeStep: "product-info",
      livePrices: [],
      forPublishPrices: [],
      steps: {
        productInfo: true,
        price: true,
        productSettings: true,
        productCategory: true,
        images: true,
      },
      hideTutorials: false,
    };
  }

  componentDidMount() {
    const userData = JSON.parse(getSession("userData"));
    const pageInfo = JSON.parse(getSession("pageInfo"));

    if (pageInfo && pageInfo.pageType === "5cd141d10d437be772373ddb") {
      // If pageType Grocery
      this.setState({ isGrocery: true });
      this.setState({ pageType: "grocery" });
    } else if (pageInfo && pageInfo.pageType === "5dea2304f6bba08323a3ddce") {
      // If pageType SB Tours
      this.setState({ pageType: "sb-tours" });
    }

    if (userData !== null) {
      this.setState({
        hideTutorials:
          pageInfo && pageInfo.hideTutorials && pageInfo.hideTutorials === true
            ? true
            : false,
      });
      this.props.getProductTypes((error, result) => {
        if (!error && result) {
          this.setState({ productTypes: result });
        }
      });
    } else {
      this.props.history.push("/login");
      window.location.reload();
    }
  }

  handleChange = (e) => {
    let { name, value, type } = e.target;
    if (type === "checkbox") {
      const checked = e.target.checked;
      value = checked;
    }
    this.setState({
      product: {
        ...this.state.product,
        [name]: value,
      },
    });
  };
  handleDisableBarcode = (e) => {
    this.setState((prevState) => ({
      disableBarcode: !prevState.disableBarcode,
    }));
  };

  handleDisableItemcode = (e) => {
    this.setState((prevState) => ({
      disableItemcode: !prevState.disableItemcode,
    }));
  };
  handleChangeSection = (e) => {
    let { name, value } = e.target;
    this.setState({
      section: {
        ...this.state.section,
        [name]: value,
      },
    });
  };

  onDismiss = () => {
    this.setState({ hideTutorials: true });
    let pageInfo = JSON.parse(getSession("pageInfo"));
    pageInfo.hideTutorials = true;
    setSession("pageInfo", JSON.stringify(pageInfo));
  };

  onHideTutorials = () => {
    if (
      !window.confirm("Are you sure you want to hide this section permanently?")
    ) {
      return false;
    }
    let pageInfo = JSON.parse(getSession("pageInfo"));
    let userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;
    const place = {
      hideTutorials: true,
    };
    api(sessionToken)
      .patch(`/provider/places/${pageInfo._id}`, place)
      .then((response) => {
        if (response && response.data && response.data.status === "ok") {
          this.setState({ hideTutorials: true });
          pageInfo.hideTutorials = true;
          setSession("pageInfo", JSON.stringify(pageInfo));
        } else {
          this.showNotificationError(
            "An unknown error occured. Please try again!"
          );
        }
      })
      .catch((error) => {
        this.setState({ submitted: false, isSaving: false });
        if (error.response && typeof error.response === "string") {
          this.showNotificationError(error.response);
        } else {
          this.showNotificationError(
            "An unknown error occured. Please try again!"
          );
        }
      });
  };

  showNotification(message) {
    if (message) {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "success",
        icon: "",
        autoDismiss: 5,
      };
      this.refs.notify.notificationAlert(notification);
    }
  }

  showNotificationError(message) {
    if (message) {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "danger",
        icon: "",
        autoDismiss: 5,
      };
      this.refs.notify.notificationAlert(notification);
    }
  }
  handleChangeExpireDate = (date) => {
    this.setState({
      expirationDate: date,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const userData = JSON.parse(getSession("userData"));
    const pageInfo = JSON.parse(getSession("pageInfo"));

    if (pageInfo && pageInfo.pageType === "5cd141d10d437be772373ddb") {
      // If pageType Grocery
      this.setState({ isGrocery: true });
    }

    if (userData) {
      const sessionToken = userData.sessionToken;
      const product = { ...this.state.product };
      if (
        product.name &&
        product.productType &&
        !isNaN(product.price) &&
        product.price > 0 &&
        product.placeId
      ) {
        if (!window.confirm("Do you want to save this item?")) {
          return false;
        }

        this.setState({ submitted: true, isSaving: true });

        if (product) {
          product.tags = [];
          product.categories = [];
          product.productCategories = [];
          product.options = {
            DineIn: false,
            PickUp: false,
            Delivery: false,
          };
          product.containerFee = {
            supportedOrderOption: {
              pickup: false,
              delivery: false,
            },
            amount: 0,
          };
        }

        this.props.addProduct(product, sessionToken, (error, result) => {
          if (result) {
            setSession("hasProducts", true);
            this.setState({ submitted: false, isSaving: false });
            if (result._id) {
              this.showNotification("New product has been added.");
              setTimeout(() => {
                this.props.history.push(
                  `/products/${result._id}/edit?message=Complete the product by adding photos &amp; category etc.`
                );
              }, 3000);
            } else {
              this.showNotification("New product has been added.");
              setTimeout(() => {
                this.props.history.push("/products");
              }, 3000);
            }
          } else {
            if (error) {
              this.setState({ submitted: false, isSaving: false });
              this.showNotificationError(error.response);
            } else {
              this.setState({ submitted: false, isSaving: false });
              this.showNotificationError(
                "An unknown error occured. Please try again."
              );
            }
          }
        });
      } else {
        this.setState({ submitted: true });
        this.showNotificationError(
          "Some fields are required. Please fill in the required fields."
        );
      }
    } else {
      this.props.history.push("/login");
      window.location.reload();
    }
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  renderProductTypes() {
    if (
      this.state.productTypes !== undefined ||
      this.state.productTypes !== null
    ) {
      const activeProductTypes = this.state.productTypes.filter(
        (item) => item.isActive
      );
      return activeProductTypes.map((item, index) => (
        <option key={index} value={item._id}>
          {item.name}
        </option>
      ));
    }
  }
  handleChangeVolume = (e) => {
    const { name, value } = e.target;
    const index = e.currentTarget.dataset.idx;
    const volumes = this.state.product.volumes;
    volumes[index][name] = value;
    this.setState({
      product: {
        ...this.state.product,
        volumes: volumes,
      },
    });
  };
  renderPlaces() {
    if (this.state.places !== undefined || this.state.places !== null) {
      return this.state.places.map((item, index) => (
        <option key={index} value={item.place._id}>
          {item.place.name}
        </option>
      ));
    }
  }

  renderVerticalNavBar() {
    return (
      <Nav vertical>
        <NavItem>
          <NavLink
            className={
              "nav-link-vertical" +
              (this.state.activeStep && this.state.activeStep === "product-info"
                ? " active-link"
                : "")
            }
            to="#"
            onClick={() => {
              this.setState({ activeStep: "product-info" });
            }}
          >
            Product Information
            <span style={{ float: "right" }}>
              <Fa icon="chevron-right" />
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={
              "nav-link-vertical" +
              (this.state.activeStep && this.state.activeStep === "price"
                ? " active-link"
                : "") +
              (this.state.steps.price === false ? " disabled" : "")
            }
            to="#"
            onClick={() => {
              if (this.state.steps.price === true) {
                this.setState({ activeStep: "price" });
              }
            }}
          >
            Price{" "}
            <span style={{ float: "right" }}>
              <Fa icon="chevron-right" />
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={
              "nav-link-vertical" +
              (this.state.activeStep &&
              this.state.activeStep === "product-settings"
                ? " active-link"
                : "") +
              (this.state.steps.productSettings === false ? " disabled" : "")
            }
            to="#"
            onClick={() => {
              if (this.state.steps.productSettings === true) {
                this.setState({ activeStep: "product-settings" });
              }
            }}
          >
            Product Settings{" "}
            <span style={{ float: "right" }}>
              <Fa icon="chevron-right" />
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={
              "nav-link-vertical" +
              (this.state.activeStep &&
              this.state.activeStep === "product-category"
                ? " active-link"
                : "") +
              (this.state.steps.productCategory === false ? " disabled" : "")
            }
            to="#"
            onClick={() => {
              if (this.state.steps.productCategory === true) {
                this.setState({ activeStep: "product-category" });
              }
            }}
          >
            Product Category{" "}
            <span style={{ float: "right" }}>
              <Fa icon="chevron-right" />
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={
              "nav-link-vertical" +
              (this.state.activeStep && this.state.activeStep === "images"
                ? " active-link"
                : "") +
              (this.state.steps.images === false ? " disabled" : "")
            }
            to="#"
            onClick={() => {
              if (this.state.steps.images === true) {
                this.setState({ activeStep: "images" });
              }
            }}
          >
            Images{" "}
            <span style={{ float: "right" }}>
              <Fa icon="chevron-right" />
            </span>
          </NavLink>
        </NavItem>
      </Nav>
    );
  }

  renderProductInfoStep(product) {
    const { submitted } = this.state;
    return (
      <>
        <Row>
          {" "}
          <Col lg="4" md="4" sm="12">
            <FormGroup>
              <Row>
                <Switch
                  onClick={() => {
                    // const nextStatus = !item.forSale;
                    // this.toggleSwitchForSale(item);
                    // productsArr[index].forSale = nextStatus;
                    // this.setState({
                    //   products: productsArr,
                    // });
                  }}
                  on={false}
                />{" "}
                <Col style={{ paddingTop: "10px", paddingLeft: "0px" }}>
                  <Label htmlFor="name" className="control-label">
                    For Sale (Availability)
                  </Label>
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Col lg="4" md="4" sm="12">
            <FormGroup>
              <Row>
                <Switch
                  onClick={() => {
                    // const nextStatus = !item.forSale;
                    // this.toggleSwitchForSale(item);
                    // productsArr[index].forSale = nextStatus;
                    // this.setState({
                    //   products: productsArr,
                    // });
                  }}
                  on={false}
                />{" "}
                <Col style={{ paddingTop: "10px", paddingLeft: "0px" }}>
                  <Label htmlFor="name" className="control-label">
                    Publish
                  </Label>
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Col lg="4" md="4" sm="12">
            <FormGroup>
              <Row>
                <Switch
                  onClick={() => {
                    // const nextStatus = !item.forSale;
                    // this.toggleSwitchForSale(item);
                    // productsArr[index].forSale = nextStatus;
                    // this.setState({
                    //   products: productsArr,
                    // });
                  }}
                  on={false}
                />{" "}
                <Col style={{ paddingTop: "10px", paddingLeft: "0px" }}>
                  <Label htmlFor="name" className="control-label">
                    Auto-Confirm
                  </Label>
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="6" md="6" sm="12">
            <FormGroup
              className={
                submitted && !this.state.category.name ? " has-danger" : ""
              }
            >
              <label htmlFor="name" className="control-label">
                Expiration Date
              </label>

              {/* <DatePicker
                id="expirationDate"
                name="expirationDate"
                className="form-control"
                selectsStart
                startDate={this.state.expirationDate}
                endDate={this.state.expirationDate}
                selected={this.state.expirationDate}
                onChange={this.handleChangeExpireDate}
                dateFormat="MMMM d, yyyy"
                autoComplete="off"
              /> */}
              {/* <DatePicker
                      id="dateTime"
                      name="dateTime"
                      className="form-control"
                      autoComplete="off"
                      selectsStart
                      startDate={this.state.expirationDate}
                      minDate={this.state.expirationDate}
                      selected={this.state.highlight.dateTime}
                      onChange={this.handleChangeDateTime}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="MMM d, yyyy h:mm aa"
                    /> */}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="6" md="6" sm="12">
            <FormGroup
              className={submitted && !product.name ? " has-danger" : ""}
            >
              <Label htmlFor="name" className="control-label">
                Restored Product Name
              </Label>
              <Input
                id="name"
                name="name"
                className="name"
                placeholder="Product Name"
                type="text"
                value={product.name}
                onChange={this.handleChange}
                autocomplete="name"
              />
            </FormGroup>
          </Col>
          <Col lg="6" md="6" sm="12">
            <FormGroup
              className={
                this.state.submitted && !product.productType
                  ? " has-danger"
                  : ""
              }
            >
              <Label htmlFor="productType" className="control-label">
                Product Type
              </Label>
              <Input
                id="productType"
                name="productType"
                className="productType"
                placeholder="Product Type"
                type="select"
                value={product.productType}
                onChange={this.handleChange}
              >
                <option value="">Select One</option>
                {this.renderProductTypes()}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <FormGroup>
              <Label htmlFor="description" className="control-label">
                Description
              </Label>
              <Input
                id="description"
                name="description"
                placeholder="Description"
                type="textarea"
                value={product.description}
                onChange={this.handleChange}
                style={{ height: "150px" }}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <Row>
              <Col md="4">
                <FormGroup className="enable-field-group">
                  <label htmlFor="barcode" className="control-label">
                    Barcode
                  </label>
                </FormGroup>
                <FormGroup>
                  <Label check>
                    <Input
                      type="checkbox"
                      className="enable-disable-checkbox"
                      id="disableBarcode"
                      name="disableBarcode"
                      defaultChecked={!this.state.disableBarcode}
                      onChange={this.handleDisableBarcode}
                    />
                    <span className="form-check-sign">
                      <span className="check" />
                    </span>
                    Enable Barcode
                  </Label>

                  <Input
                    readOnly={this.state.disableBarcode}
                    id="barcode"
                    name="barcode"
                    className="barcode"
                    placeholder="Barcode"
                    type="text"
                    value={product.barcode}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col style={{ paddingTop: "50px", paddingLeft: "0px" }}>
                <Button
                  alt="Scan Barcode"
                  title="Scan Barcode"
                  className="btn btn-sm btn-round btn-info "
                  onClick=""
                >
                  Scan Barcode
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <FormGroup className="enable-field-group">
                  <label htmlFor="barcode" className="control-label">
                    Item Code
                  </label>
                </FormGroup>
                <FormGroup>
                  <Label check>
                    <Input
                      type="checkbox"
                      className="enable-disable-checkbox"
                      id="disableItemcode"
                      name="disableItemcode"
                      defaultChecked={!this.state.disableItemcode}
                      onChange={this.handleDisableItemcode}
                    />
                    <span className="form-check-sign">
                      <span className="check" />
                    </span>
                    Enable Item Code
                  </Label>
                  <Input
                    readOnly={this.state.disableItemcode}
                    id="itemCode"
                    name="itemCode"
                    className="itemCode"
                    placeholder="Item Code"
                    type="text"
                    value={product.itemCode}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col style={{ paddingTop: "50px", paddingLeft: "0px" }}>
                <Button
                  alt="Scan Code"
                  title="Scan Code"
                  className="btn btn-sm btn-round btn-info "
                  onClick=""
                >
                  Scan Code
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  renderPriceStep(product) {
    const { submitted } = this.state;
    return (
      <>
        <Row>
          <Col md="4">
            <FormGroup
              className={
                submitted &&
                (!product.price || isNaN(product.price) || product.price <= 0)
                  ? " has-danger"
                  : ""
              }
            >
              <label htmlFor="price" className="control-label">
                Price <em className="text-muted">(Required)</em>
              </label>
              <Input
                id="price"
                name="price"
                placeholder="&#8369;"
                type="text"
                value={product.price}
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
          <Col className="px-md-1" md="4">
            <FormGroup>
              <label htmlFor="comparePrice" className="control-label">
                Compare Price
              </label>
              <Input
                id="comparePrice"
                name="comparePrice"
                placeholder="&#8369;"
                type="text"
                value={product.comparePrice}
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="12" lg="12">
            <p
              className="control-label"
              style={{
                textTransform: "capitalizes",
                fontWeight: 500,
                fontSize: "15px",
              }}
            >
              Live Price List
            </p>
            <FormGroup>
              <Table className="tablesorter table-hover" size="sm">
                <thead className="text-primary">
                  <tr>
                    <th>Volume</th>
                    <th>Variation</th>
                    <th>Price/Unit</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.livePrices.length > 0 ? (
                    this.state.livePrices.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.volume.description}</td>
                          <td>{item.variation.description}</td>
                          <td>
                            &#8369; {numberWithCommas(item.price)}
                            <em>{item.unit}</em>
                          </td>
                          <td>
                            <Button
                              alt="Edit"
                              title="Edit"
                              style={{ marginRight: "10px" }}
                              className="btn-round btn-sm"
                              color="info"
                              type="button"
                              data-idx={item.id}
                              onClick={this.handleEditPriceClick}
                            >
                              Edit
                            </Button>
                            <Button
                              alt="Remove"
                              title="Remove"
                              style={{ marginRight: "10px" }}
                              className="btn-round btn-sm btn-light"
                              type="button"
                              data-idx={item.id}
                              onClick={this.handleUnPublishPrice}
                            >
                              Unpublish
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <>
                      <tr>
                        <td colSpan={4}>
                          <h5 className="text-danger">
                            <em>No items added.</em>
                          </h5>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="12" lg="12">
            <p
              className="control-label"
              style={{
                textTransform: "capitalizes",
                fontWeight: 500,
                fontSize: "15px",
              }}
            >
              For Publish Price List
            </p>
            <FormGroup>
              <Table className="tablesorter table-hover" size="sm">
                <thead className="text-primary">
                  <tr>
                    <th>Volume</th>
                    <th>Variation</th>
                    <th>Price/Unit</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.forPublishPrices.length > 0 ? (
                    this.state.forPublishPrices.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {item.volume && item.volume.description
                              ? item.volume.description
                              : ""}
                          </td>
                          <td>
                            {item.volume && item.variation.description
                              ? item.variation.description
                              : ""}
                          </td>
                          <td>
                            &#8369;{" "}
                            {numberWithCommas(item.price ? item.price : 0)}
                          </td>
                          <td>
                            <Button
                              alt="Edit"
                              title="Edit"
                              style={{ marginRight: "10px" }}
                              className="btn-round btn-sm"
                              color="info"
                              type="button"
                              data-idx={item.id}
                              onClick={this.handleEditPriceClick}
                            >
                              Edit
                            </Button>
                            <Button
                              alt="Publish"
                              title="Publish"
                              style={{ marginRight: "10px" }}
                              className="btn-round btn-sm"
                              color="info"
                              type="button"
                              data-idx={item.id}
                              onClick={this.handlePublishPrice}
                            >
                              Publish
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <>
                      <tr>
                        <td colSpan={4}>
                          <h5 className="text-danger">
                            <em>No items added.</em>
                          </h5>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }

  renderProductSettings(product) {
    const { submitted } = this.state;
    return (
      <>
        <Row>
          <Col md="4">
            <FormGroup>
              <span>
                <Switch
                  onClick={() => {
                    this.setState({
                      product: {
                        ...this.state.product,
                        forSale: !product.forSale,
                      },
                    });
                  }}
                  on={product.forSale}
                />
                For Sale (Availability)
              </span>
            </FormGroup>
            <FormGroup>
              <span>
                <Switch
                  onClick={() => {
                    this.setState({
                      product: {
                        ...this.state.product,
                        autofullfill: !product.autofullfill,
                      },
                    });
                  }}
                  on={product.autofullfill}
                />
                Auto-confirm
              </span>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <span>
                <Switch
                  onClick={() => {
                    this.setState({
                      product: {
                        ...this.state.product,
                        isActive: !product.isActive,
                      },
                    });
                  }}
                  on={product.isActive}
                />
                Publish
              </span>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="4" style={{ marginBottom: "15px" }}>
            <FormGroup>
              <span className="control-label">Pick-up Options</span>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  className=""
                  id="forPickup"
                  name="forPickup"
                  defaultChecked={product.forPickup}
                  onChange={this.handleChange}
                />
                <span className="form-check-sign">
                  <span className="check" />
                </span>
                Pickup
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  className=""
                  id="forDelivery"
                  name="forDelivery"
                  defaultChecked={product.forDelivery}
                  onChange={this.handleChange}
                />
                <span className="form-check-sign">
                  <span className="check" />
                </span>
                Delivery
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  className=""
                  id="forDinein"
                  name="forDinein"
                  defaultChecked={product.forDinein}
                  onChange={this.handleChange}
                />
                <span className="form-check-sign">
                  <span className="check" />
                </span>
                Dine In
              </Label>
            </FormGroup>
          </Col>
          <Col className="px-md-1" md="4" style={{ marginBottom: "15px" }}>
            <Row>
              <Col md="12">
                <FormGroup>
                  <span className="control-label">Charges and Fees</span>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <span className="control-label">
                    <Switch
                      onClick={() => {
                        const nextStatus = !product.containerFeeDelivery;
                        this.setState({
                          product: {
                            ...this.state.product,
                            containerFeeDelivery: nextStatus,
                          },
                        });
                      }}
                      on={product.containerFeeDelivery}
                    />
                    Container Fee
                  </span>
                </FormGroup>
                <FormGroup>
                  <Input
                    id="containerFeeAmt"
                    name="containerFeeAmt"
                    className="containerFeeAmt"
                    placeholder="Enter Amount"
                    type="text"
                    value={product.containerFeeAmt}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <span className="control-label">
                    <Switch
                      onClick={() => {
                        const nextStatus = !product.containerFeePickup;
                        this.setState({
                          product: {
                            ...this.state.product,
                            containerFeePickup: nextStatus,
                          },
                        });
                      }}
                      on={this.state.product.containerFeePickup}
                    />
                    Applicable for Pickup
                  </span>
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md="4" style={{ marginBottom: "15px" }}>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="unit" className="control-label">
                    Product Unit
                  </Label>
                  <Input
                    id="unit"
                    name="unit"
                    className="unit"
                    placeholder="Enter Unit (kg, lbs, L)"
                    type="text"
                    value={product.unit}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="limit" className="control-label">
                    Product Limit (per order)
                  </Label>
                  <Input
                    id="limit"
                    name="limit"
                    className="limit"
                    placeholder="Enter product limit"
                    type="text"
                    value={product.limit}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6" lg="6">
            <FormGroup>
              <Label htmlFor="kind" className="control-label">
                Kind
              </Label>
              <Input
                id="kind"
                name="kind"
                className="kind"
                placeholder="Kind"
                type="select"
                value={product.kind}
                onChange={this.handleChange}
              >
                <option value="">Select one</option>
                <option value="crops">Crops</option>
                <option value="goods">Goods</option>
              </Input>
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="6">
            <FormGroup>
              <Label htmlFor="unit" className="control-label">
                Unit
              </Label>
              <Input
                name="uni2"
                className="unit"
                placeholder="Unit"
                type="text"
                value={product.unit}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <span className="control-label pr-2">Volumes</span>
              <Table
                style={{ minWidth: "600px" }}
                className="tablesorter table-hover"
              >
                <thead className="text-primary">
                  <tr>
                    <th>Description</th>
                    <th>Value</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.product.volumes.length > 0 ? (
                    this.state.product.volumes.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <FormGroup
                              className={
                                submitted && !item.description
                                  ? " has-danger"
                                  : ""
                              }
                            >
                              <Input
                                name="description"
                                className="description"
                                placeholder="Description"
                                type="text"
                                value={item.description}
                                data-idx={i}
                                onChange={this.handleChangeVolume}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup>
                              <InputGroup>
                                <Input
                                  name="value"
                                  className="value"
                                  placeholder="Value"
                                  type="text"
                                  defaultValue={item.value ? item.value : 0}
                                  data-idx={i}
                                  onChange={this.handleChangeVolume}
                                />
                                <InputGroupText>
                                  {this.state.unit}
                                </InputGroupText>
                              </InputGroup>
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup>
                              <span>
                                <Switch
                                  onClick={() => {
                                    const nextStatus = !item.active;
                                    const product = this.state.product;
                                    let volumes = product.volumes;
                                    volumes[i].active = nextStatus;
                                    this.setState({
                                      product: {
                                        ...this.state.product,
                                        volumes: volumes,
                                      },
                                    });
                                  }}
                                  on={item.active}
                                />
                                Active
                              </span>
                            </FormGroup>
                          </td>
                          <td>
                            <Button
                              alt="Remove"
                              title="Remove"
                              style={{ marginRight: "10px" }}
                              className="btn-round btn-sm"
                              color="danger"
                              type="button"
                              data-idx={i}
                              onClick={this.handleRemoveClickVolume}
                            >
                              <Fa icon="times" />
                            </Button>
                          </td>
                          <td></td>
                        </tr>
                      );
                    })
                  ) : (
                    <>
                      <tr>
                        <td colSpan={4}>
                          <h5 className="text-danger">
                            <em>No items added.</em>
                          </h5>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
              <Button
                alt="Add"
                title="Add"
                className="btn-round btn-sm"
                color="info"
                type="button"
                onClick={this.handleAddClickVolume}
              >
                <Fa icon="plus" />
                &nbsp; Add
              </Button>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <span className="control-label pr-2">Variations</span>
              <Table
                style={{ minWidth: "600px" }}
                className="tablesorter table-hover"
              >
                <thead className="text-primary">
                  <tr>
                    <th>Description</th>
                    <th>Status</th>
                    <th colSpan={2}>Photo</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.product.variations.length > 0 ? (
                    this.state.product.variations.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <FormGroup
                              className={
                                submitted && !item.description
                                  ? " has-danger"
                                  : ""
                              }
                            >
                              <Input
                                name="description"
                                className="description"
                                placeholder="Description"
                                type="text"
                                value={item.description}
                                data-idx={i}
                                onChange={this.handleChangeVariation}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup>
                              <span>
                                <Switch
                                  onClick={() => {
                                    const nextStatus = !item.active;
                                    const product = this.state.product;
                                    let variations = product.variations;
                                    variations[i].active = nextStatus;
                                    this.setState({
                                      product: {
                                        ...this.state.product,
                                        variations: variations,
                                      },
                                    });
                                  }}
                                  on={item.active}
                                />
                                Active
                              </span>
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup>
                              <div
                                className="upload-photo"
                                style={{
                                  margin: "10px auto",
                                  border: "1px solid #ccc",
                                  backgroundColor: "#efefef",
                                }}
                              >
                                <Label
                                  htmlFor={"images=" + i}
                                  className="control-label"
                                  style={{ width: "100%", textAlign: "center" }}
                                >
                                  Upload Photo
                                </Label>
                                <Input
                                  id={"images=" + i}
                                  name="images[]"
                                  label="upload file"
                                  type="file"
                                  key={i}
                                  style={divStyle}
                                  onChange={(e) => {
                                    this.handleVariationImageUpload(i, e);
                                  }}
                                />
                              </div>
                            </FormGroup>
                          </td>
                          <td>
                            <ul className="product-photos">
                              {item.photo && (
                                <li
                                  key={item.photo.original}
                                  style={{ width: "75px" }}
                                >
                                  <img
                                    alt={item.photo.original}
                                    title={item.photo.original}
                                    src={item.photo.thumb}
                                  />
                                  <button
                                    data-id={item.photo.original}
                                    type="button"
                                    onClick={(e) => {
                                      this.removeVariationImage(i, e);
                                    }}
                                    className="remove-image"
                                  >
                                    <span style={{ color: "#fefefe" }}>
                                      &times;
                                    </span>
                                  </button>
                                </li>
                              )}
                            </ul>
                          </td>
                          <td>
                            <Button
                              alt="Remove"
                              title="Remove"
                              style={{ marginRight: "10px" }}
                              className="btn-round btn-sm"
                              color="danger"
                              type="button"
                              data-idx={i}
                              onClick={this.handleRemoveClickVariation}
                            >
                              <Fa icon="times" />
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <>
                      <tr>
                        <td colSpan={5}>
                          <h5 className="text-danger">
                            <em>No items added.</em>
                          </h5>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
              <Button
                alt="Add"
                title="Add"
                className="btn-round btn-sm"
                color="info"
                type="button"
                onClick={this.handleAddClickVariation}
              >
                <Fa icon="plus" />
                &nbsp;Add
              </Button>
              {this.state.isUploadingVariationPhoto && (
                <span style={{ border: "0", padding: "10px" }}>
                  <SyncLoader
                    sizeUnit={"px"}
                    size={15}
                    color={"#1d8cf8"}
                    loading={this.state.isUploadingVariationPhoto}
                  />
                </span>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6" lg="6">
            <FormGroup>
              <Label htmlFor="reorderPoint" className="control-label">
                Reorder Point
              </Label>
              <InputGroup>
                <Input
                  id="reorderPoint"
                  name="reorderPoint"
                  className="reorderPoint"
                  placeholder="Reorder Point"
                  type="number"
                  defaultValue={product.reorderPoint ? product.reorderPoint : 0}
                  onChange={this.handleChange}
                />
                <InputGroupText>{this.state.reorderPointUnit}</InputGroupText>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }

  renderProductCategory(categoriesTree, icons) {
    return (
      <>
        <Row>
          <Col md="12">
            <FormGroup>
              <span className="control-label">Select Product Categories</span>
              <CheckboxTree
                nodes={categoriesTree}
                checked={this.state.checked}
                expanded={this.state.expanded}
                onCheck={(checked) => this.setState({ checked })}
                onExpand={(expanded) => this.setState({ expanded })}
                showNodeIcon={false}
                icons={icons}
                nativeCheckboxes
              />
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }

  renderImages(product) {
    return (
      <>
        <Row>
          <Col md="6">
            <FormGroup>
              <div
                className="upload-photo"
                style={{
                  margin: "10px auto",
                  border: "1px solid #ccc",
                  backgroundColor: "#efefef",
                }}
              >
                <Label htmlFor="images" className="control-label">
                  Upload Photo
                </Label>
                <Input
                  id="images"
                  name="images"
                  label="upload file"
                  type="file"
                  onChange={this.handleFileUpload}
                  style={divStyle}
                />
                <ul className="product-photos">
                  {product.photos.length > 0 && (
                    <>
                      {product.photos.map(({ original, thumb, _id: id }) => (
                        <li key={id}>
                          <img alt={original} title={id} src={thumb} />
                          <button
                            data-id={id}
                            type="button"
                            onClick={this.removeImage}
                            className="remove-image"
                          >
                            <span style={{ color: "#fefefe" }}>&times;</span>
                          </button>
                        </li>
                      ))}
                    </>
                  )}
                  {this.state.isUploading && (
                    <li style={{ border: "0", padding: "10px" }}>
                      <SyncLoader
                        sizeUnit={"px"}
                        size={15}
                        color={"#1d8cf8"}
                        loading={this.state.isUploading}
                      />
                    </li>
                  )}
                </ul>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
  renderNoPageAdded() {
    return (
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref="notify" />
        </div>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Alert color="danger">
                  <h4 className="alert-heading">No Page Added</h4>
                  <hr />
                  <p className="mb-0">
                    You have not added a page yet. Click{" "}
                    <Link to="/add-page">here</Link> to add a new page.
                  </p>
                </Alert>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  renderPageNotVerified() {
    return (
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref="notify" />
        </div>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Alert color="danger">
                  <h4 className="alert-heading">
                    You Have Not Agreed with our Terms & Policies
                  </h4>
                  <hr />
                  <p className="mb-0">
                    You must agree with our Terms & Policies. Click{" "}
                    <Link to="/statement-of-agreement">here</Link> to read our
                    Terms & Policies.
                  </p>
                </Alert>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  renderPageDisabled() {
    return (
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref="notify" />
        </div>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Alert color="danger">
                  <h4 className="alert-heading">This page is disabled.</h4>
                  <hr />
                  <p className="mb-0">
                    Click <Link to="/products">here</Link> to go back to the
                    main page.
                  </p>
                </Alert>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    let { pageType } = this.state;
    let categoriesTree = [];
    const pageInfo = JSON.parse(getSession("pageInfo"));
    const productCategories = this.state.productCategories;
    if (productCategories instanceof Array && productCategories.length > 0) {
      const parentCategories = productCategories.filter((item) => !item.parent);
      const childCategories = productCategories.filter((item) => item.parent);
      if (parentCategories) {
        parentCategories.forEach((cat) => {
          const value = cat._id.toString();
          const label = cat.name;
          let sublist = [];
          if (pageInfo && pageInfo.pageType === "5cd141d10d437be772373ddb") {
            // If Grocery
            sublist = childCategories.filter(
              (item) => item.parent._id.toString() === value
            );
          } else {
            sublist = childCategories.filter(
              (item) => item.parent._id.toString() === value
            );
          }
          let children1 = [];
          if (sublist instanceof Array && sublist.length > 0) {
            let category1 = [];
            sublist.forEach((cat) => {
              const value = cat._id;
              const label = cat.name;
              const category = {
                value: value,
                label: label,
                children: [],
              };
              category1.push(category);
            });
            children1 = category1;
          }
          const children = children1;
          const category = {
            value: value,
            label: label,
            children: children,
          };
          categoriesTree.push(category);
        });
      }
    }
    const icons = {
      check: (
        <Fa
          className="rct-icon rct-icon-check"
          icon={["far", "check-square"]}
        />
      ),
      uncheck: (
        <Fa className="rct-icon rct-icon-uncheck" icon={["far", "square"]} />
      ),
      halfCheck: (
        <Fa
          className="rct-icon rct-icon-half-check"
          icon={["far", "check-square"]}
        />
      ),
      expandClose: (
        <Fa className="rct-icon rct-icon-expand-close" icon="chevron-right" />
      ),
      expandOpen: (
        <Fa className="rct-icon rct-icon-expand-open" icon="chevron-down" />
      ),
      expandAll: (
        <Fa className="rct-icon rct-icon-expand-all" icon="plus-square" />
      ),
      collapseAll: (
        <Fa className="rct-icon rct-icon-collapse-all" icon="minus-square" />
      ),
      parentClose: (
        <Fa
          className="rct-icon rct-icon-parent-close"
          icon={["far", "folder"]}
        />
      ),
      parentOpen: (
        <Fa
          className="rct-icon rct-icon-parent-open"
          icon={["far", "folder-open"]}
        />
      ),
      leaf: (
        <Fa className="rct-icon rct-icon-leaf-close" icon={["far", "file"]} />
      ),
    };
    if (pageInfo && pageInfo._id) {
      if (pageInfo.isVerified === true) {
        if (pageType !== "sb-tours") {
          return (
            <>
              <div className="content">
                <div className="react-notification-alert-container">
                  <NotificationAlert ref="notify" />
                </div>
                <Row>
                  <Col sm="12" md="12" lg="12">
                    <Card>
                      <Form
                        onSubmit={this.handleSubmit}
                        onKeyPress={this.onKeyPress}
                      >
                        <CardHeader>
                          <h4 className="title">Add New Restored Product</h4>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            {!pageInfo.hideTutorials && (
                              <Col sm="12">
                                <Row>
                                  <Col md="12">
                                    <Alert
                                      className="alert-compact"
                                      color="primary"
                                      isOpen={!this.state.hideTutorials}
                                      toggle={this.onDismiss}
                                      fade={false}
                                    >
                                      <h4 className="alert-heading">
                                        New on Products?
                                      </h4>
                                      <hr />
                                      <p className="mb-0">
                                        Check our videos here on how to manage
                                        your products.
                                        <br />
                                      </p>
                                      <a
                                        className="btn btn-sm btn-primary-v2"
                                        href="https://www.youtube.com/watch?v=k8VEDqbPv7w"
                                      >
                                        View Tutorials
                                      </a>
                                      <Button
                                        className="btn-fill btn-sm"
                                        color="danger"
                                        type="button"
                                        onClick={this.onHideTutorials}
                                      >
                                        Hide Permanently
                                      </Button>
                                    </Alert>
                                  </Col>
                                </Row>
                              </Col>
                            )}
                          </Row>
                          <Row>
                            <Col sm="12" md="3" lg="3">
                              {this.renderVerticalNavBar()}
                            </Col>
                            <Col sm="12" md="9" lg="9">
                              {this.state.activeStep === "product-info" &&
                              this.state.steps.productInfo === true
                                ? this.renderProductInfoStep(this.state.product)
                                : this.state.activeStep === "price" &&
                                  this.state.steps.price === true
                                ? this.renderPriceStep(this.state.product)
                                : this.state.activeStep ===
                                    "product-settings" &&
                                  this.state.steps.productSettings === true
                                ? this.renderProductSettings(this.state.product)
                                : this.state.activeStep ===
                                    "product-category" &&
                                  this.state.steps.productCategory === true
                                ? this.renderProductCategory(
                                    categoriesTree,
                                    icons
                                  )
                                : this.state.activeStep === "images" &&
                                  this.state.steps.images === true
                                ? this.renderImages(this.state.product)
                                : this.state.activeStep === "vouchers" &&
                                  this.state.steps.vouchers === true &&
                                  this.state.product.vouchers &&
                                  this.state.product.vouchers.length > 0
                                ? this.renderVouchers(this.state.product)
                                : this.renderProductInfoStep(
                                    this.state.product
                                  )}
                            </Col>
                          </Row>
                        </CardBody>
                        <CardFooter>
                          <Button
                            className="btn-round"
                            color="info"
                            type="submit"
                          >
                            Save
                          </Button>
                          <Link
                            to="/products"
                            className="btn btn-round btn-light"
                          >
                            Cancel
                          </Link>
                        </CardFooter>
                      </Form>
                    </Card>
                  </Col>
                </Row>
                <LoadingOverlay
                  active={this.state.isSaving}
                  spinner
                  text="Saving..."
                ></LoadingOverlay>
              </div>
            </>
          );
        } else if (pageType && pageType === "sb-tours") {
          return this.renderPageDisabled();
        }
      } else {
        return this.renderPageNotVerified();
      }
    } else {
      return this.renderNoPageAdded();
    }
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  getProductTypes,
  addProduct,
})(AddRestoreProduct);
const numberWithCommas = (x) => {
  return priceRound(x).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const priceRound = (price, dec) => {
  if (dec === undefined) {
    dec = 2;
  }
  if (price !== 0) {
    if (!price || isNaN(price)) {
      throw new Error("price is not a number" + price);
    }
  }
  const str = parseFloat(Math.round(price * 100) / 100).toFixed(dec);
  return str;
};
