import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getSession, setSession } from "../../config/session";
import api from "../../config/api";
import {
  getSuppliers,
  deleteSupplier,
  addSupplier,
  updateSupplier,
} from "../../layouts/Admin/actions/InventoryActions";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import queryString from "query-string";
import Pagination from "react-js-pagination";
import { PulseLoader } from "react-spinners";
import NotificationAlert from "react-notification-alert";
import LoadingOverlay from "react-loading-overlay";

import defaultProductImage from "../../assets/img/product-image-default.png";

import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Row,
  Col,
  Label,
  Media,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";

class ProductSupplier extends React.Component {
  constructor(props) {
    const defaultPage = JSON.parse(getSession("defaultPage"));
    const userData = JSON.parse(getSession("userData"));
    super(props);
    this.state = {
      suppliers: [],
      checked: [],
      defaultPage: "",
      pagination: {},
      activePage: 1,
      selectedPage: "",
      pageName: "",
      sortBy: "",
      sort: "",
      keyword: "",
      stockStatus: "",
      kind: "",
      size: 25,
      inventoryProducts: [],
      supplier: {
        id: "",
        name: "",
        address: "",
        contactPerson: "",
        contactNumber: "",
      },
      selectedSupplier: null,
      placeId: defaultPage,
      user: userData && userData.userId ? userData.userId : "",
      isLoading: true,
      isProcessing: false,
      isGrocery: false,
      hideTutorials: false,
      addSupplierModalError: "",
      submittedModal: false,
      modal: false,
      modalView: false,
    };
  }

  componentDidMount() {
    const userData = JSON.parse(getSession("userData"));
    let pageInfo = JSON.parse(getSession("pageInfo"));
    if (pageInfo && pageInfo.pageType === "5cd141d10d437be772373ddb") {
      this.setState({ isGrocery: true });
    }

    if (userData !== null) {
      let defaultPage = JSON.parse(getSession("defaultPage"));
      const sessionToken = userData.sessionToken;

      if (defaultPage) {
        if (defaultPage !== "") {
          let url = this.props.location.search;
          let query = queryString.parse(url);
          let activePage = query.page ? Number(query.page) : 1;
          let status = query.status ? query.status : "";
          let sortBy = query.sortBy ? query.sortBy : "created";
          let sort = query.sort ? query.sort : "desc";
          let keyword = query.keyword ? query.keyword : "";
          let size = query.size ? query.size : 25;
          let kind = query.kind ? query.kind : "";
          let stockStatus = query.stockStatus ? query.stockStatus : "";
          if (query.category) {
          } else {
            delete query.subCategory;
          }
          let queryStr = "?" + queryString.stringify(query);
          this.setState({
            activePage: activePage,
            selectedPage: defaultPage,
            status: status,
            sort: sort,
            sortBy: sortBy,
            keyword: keyword,
            kind,
            stockStatus,
            size,
            pageName: pageInfo && pageInfo.name ? pageInfo.name : "",
            hideTutorials:
              pageInfo &&
              pageInfo.hideTutorials &&
              pageInfo.hideTutorials === true
                ? true
                : false,
          });
          this.refreshList(queryStr, defaultPage);
          this.props.getSuppliers(sessionToken, (err, res) => {
            if (!err && res) {
              if (res && res instanceof Array && res.length > 0) {
                let products = [];
                res.forEach((item) => {
                  const productItem = {
                    value: item.id,
                    label: item.name,
                  };
                  products.push(productItem);
                });
                this.setState({ inventoryProducts: products });
              }
            }
          });
        }
      } else {
      }
    } else {
      this.props.history.push("/login");
      window.location.reload();
    }
    this._isMount = true;
  }

  componentWillUnmount() {
    this._isMount = false;
  }

  renderRows(supplier) {
    if (this.state.isLoading) {
      return (
        <tr>
          <td colSpan={5}>
            <PulseLoader
              sizeUnit={"px"}
              size={15}
              color={"#1d8cf8"}
              loading={this.state.isLoading}
            />
          </td>
        </tr>
      );
    } else {
      if (supplier instanceof Array && supplier.length > 0) {
        return supplier.map((item, index) => (
          <tr key={index}>
            <td>
              <Link
                to="#"
                onClick={() => this.toggleModal(item)}
                className="text-medium"
                alt={item.name}
                title={item.name}
              >
                {item.name}
              </Link>
            </td>
            <td className="text-medium">{item.address}</td>
            <td className="text-medium">{item.contactPerson}</td>
            <td className="text-medium">{item.contactNumber}</td>
            <td width="75" align="center">
              <Link
                to="#"
                onClick={() => this.toggleModal(item)}
                style={{ paddingRight: "10px" }}
              >
                <Fa icon="edit" />
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={this.handleDelete}
                data-item={item.id}
              >
                <Fa icon="trash-alt" />
              </Link>
            </td>
            <td>&nbsp;</td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={5}>
              <h5 className="text-danger">
                <em>No item(s) found.</em>
              </h5>
            </td>
          </tr>
        );
      }
    }
  }

  renderCategories(categories) {
    if (categories instanceof Array && categories.length > 0) {
      if (categories.length === 1) {
        return categories.map((cat) => cat.name);
      } else {
        return categories.map((cat) => cat.name + ", ");
      }
    }
  }

  renderImage(item) {
    const { name, photos } = item;

    let primaryPhoto;
    if (photos[0] !== undefined || photos[0] != null) {
      primaryPhoto = photos[0];
    } else {
      primaryPhoto = {
        thumb: defaultProductImage,
      };
    }
    return (
      <Media className="mt-1">
        {primaryPhoto && primaryPhoto.thumb && (
          <Media left middle>
            <Media
              object
              data-src={primaryPhoto.thumb}
              src={primaryPhoto.thumb}
              alt={name}
              title={name}
            />
          </Media>
        )}
      </Media>
    );
  }

  onDismiss = () => {
    this.setState({ hideTutorials: true });
    let pageInfo = JSON.parse(getSession("pageInfo"));
    pageInfo.hideTutorials = true;
    setSession("pageInfo", JSON.stringify(pageInfo));
  };

  onHideTutorials = () => {
    if (
      !window.confirm("Are you sure you want to hide this section permanently?")
    ) {
      return false;
    }
    let pageInfo = JSON.parse(getSession("pageInfo"));
    let userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;
    const place = {
      hideTutorials: true,
    };
    api(sessionToken)
      .patch(`/provider/places/${pageInfo._id}`, place)
      .then((response) => {
        if (response && response.data && response.data.status === "ok") {
          this.setState({ hideTutorials: true });
          pageInfo.hideTutorials = true;
          setSession("pageInfo", JSON.stringify(pageInfo));
        } else {
          this.showNotificationError(
            "An unknown error occured. Please try again!"
          );
        }
      })
      .catch((error) => {
        this.setState({ submitted: false, isSaving: false });
        if (error.response && typeof error.response === "string") {
          this.showNotificationError(error.response);
        } else {
          this.showNotificationError(
            "An unknown error occured. Please try again!"
          );
        }
      });
  };

  toggleModal = (supplier) => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      supplier: supplier || {},
    }));
  };

  showNotification(message) {
    if (message && typeof message === "string") {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "success",
        icon: "",
        autoDismiss: 2,
      };
      if (
        notification &&
        this.refs.notify &&
        this.refs.notify.notificationAlert
      ) {
        this.refs.notify.notificationAlert(notification);
      }
    }
  }

  showNotificationError(message) {
    if (message && typeof message === "string") {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "danger",
        icon: "",
        autoDismiss: 2,
      };
      if (
        notification &&
        this.refs.notify &&
        this.refs.notify.notificationAlert
      ) {
        this.refs.notify.notificationAlert(notification);
      }
    }
  }

  handleChangeKeyword = (e) => {
    let { value } = e.target;
    this.setState({ keyword: value });
  };

  handleEnter = (e) => {
    let { key } = e;
    if (key === "Enter") {
      this.setState({ isLoading: true, activePage: 1 });
      let { keyword } = this.state;

      let defaultPage = JSON.parse(getSession("defaultPage"));
      let url = this.props.location.search;
      let query = queryString.parse(url);
      delete query.message;
      delete query.page;
      if (keyword !== "") {
        query.keyword = keyword.trim();
      } else {
        delete query.keyword;
      }
      let queryStr = "?" + queryString.stringify(query);
      this.refreshList(queryStr, defaultPage);
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ isLoading: true });
    let defaultPage = JSON.parse(getSession("defaultPage"));

    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    query.page = pageNumber;
    let queryStr = "?" + queryString.stringify(query);

    this.setState({ activePage: pageNumber });
    this.refreshList(queryStr, defaultPage);
  };

  handleChangePerPage = (e) => {
    this.setState({ isLoading: true });
    let { value } = e.target;

    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    delete query.page;
    if (value !== "") {
      query.size = value;
    } else {
      delete query.size;
    }
    let queryStr = "?" + queryString.stringify(query);
    this.setState({ size: value, activePage: 1 });
    this.refreshList(queryStr, defaultPage);
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      supplier: {
        ...this.state.supplier,
        [name]: value,
      },
    });
  };

  handleSubmitSupplier = (e) => {
    e.preventDefault();
    const userData = JSON.parse(getSession("userData"));

    if (userData) {
      const sessionToken = userData.sessionToken;
      const supplier = { ...this.state.supplier };
      let hasError = false;
      if (supplier.name) {
        this.setState({ submitted: true });
        if (!hasError) {
          if (!window.confirm("Do you want to save this item?")) {
            return false;
          }
          this.setState({ isSaving: true });
          // if (!supplier.user) reject.user = userData.userId;

          this.props.addSupplier(supplier, sessionToken, (err, res) => {
            if (res) {
              this.setState({ submitted: false, isSaving: false });
              if (res.id) {
                this.showNotification("Supplier has been added");
                this.toggleModal();

                let defaultPage = JSON.parse(getSession("defaultPage"));
                let url = this.props.location.search;
                let query = queryString.parse(url);
                delete query.message;
                delete query.page;
                let queryStr = "?" + queryString.stringify(query);
                this.refreshList(queryStr, defaultPage);
              }
            } else {
              if (err) {
                this.setState({ submitted: false, isSaving: false });
                const { response } = err;
                let msg = "";
                if (typeof response === "string") msg = response;
                else {
                  if (
                    response.data !== null &&
                    response.data.message !== null &&
                    typeof response.data.message === "string"
                  ) {
                    msg = response.data.message;
                    this.showNotificationError(msg);
                  }
                }
                this.setState({ addSupplierModalError: msg });
              } else {
                this.setState({
                  submitted: false,
                  isSaving: false,
                  addSupplierModalError:
                    "An unknown error occured. Please try again.",
                });
              }
            }
          });
        }
      } else {
        this.setState({ submitted: true });
        this.showNotificationError(
          "Some fields are required. Please fill in the required fields"
        );
      }
    } else {
      this.props.history.push("/login");
      window.location.reload();
    }
  };
  handleUpdateSupplier = (e) => {
    e.preventDefault();
    const userData = JSON.parse(getSession("userData"));
    if (userData) {
      const sessionToken = userData.sessionToken;
      const { supplier } = this.state;
      let hasError = false;
      if (supplier.name) {
        this.setState({ submitted: true });

        if (!hasError) {
          if (!window.confirm("Do you want to update this item?")) {
            return false;
          }

          this.setState({ isSaving: true });

          this.props.updateSupplier(
            supplier.id,
            supplier,
            sessionToken,
            (err, res) => {
              if (res) {
                this.setState({ submitted: false, isSaving: false });
                let msg = "Supplier changes has been saved.";
                if (res && res.status === "success") {
                  if (res.message && typeof res.message === "string") {
                    msg = res.message;
                  }
                  this.showNotification(msg);
                  this.toggleModal();

                  let defaultPage = JSON.parse(getSession("defaultPage"));
                  let url = this.props.location.search;
                  let query = queryString.parse(url);
                  delete query.message;
                  delete query.page;
                  let queryStr = "?" + queryString.stringify(query);
                  this.refreshList(queryStr, defaultPage);
                }
              } else {
                if (err) {
                  console.error({ err });
                  this.setState({ submitted: false, isSaving: false });
                  const { response } = err;
                  let msg = "";
                  if (typeof response === "string") msg = response;
                  else {
                    if (
                      response.data !== null &&
                      response.data.message !== null &&
                      typeof response.data.message === "string"
                    ) {
                      msg = response.data.message;
                    }
                  }
                  this.showNotificationError(msg);
                } else {
                  this.setState({ submitted: false, isSaving: false });
                  this.showNotificationError(
                    "An unknown error occured. Please try again."
                  );
                }
              }
            }
          );
        }
      } else {
        this.showNotificationError(
          "Some fields are required. Please fill in the required fields"
        );
      }
    } else {
      this.props.history.push("/login");
      window.location.reload();
    }
  };

  handleDelete = (e) => {
    if (!window.confirm("Do you want to delete this supplier?")) {
      return false;
    }
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    const message = "Supplier has been deleted successfully.";
    let queryStr = "?" + queryString.stringify(query);
    const productId = e.currentTarget.dataset.item;
    if (productId) {
      const userData = JSON.parse(getSession("userData"));
      if (userData) {
        const sessionToken = userData.sessionToken;
        this.props.deleteSupplier(productId, sessionToken, (error, result) => {
          if (!error && result) {
            if (result.status === 200) {
              this.showNotification(message);
              this.refreshList(queryStr, defaultPage);
            }
          } else {
            if (error) {
              if (
                error.response &&
                error.response.status &&
                error.response.status === 403
              ) {
                setTimeout(() => {
                  this.setState({ isLoading: true });
                  let defaultPage = JSON.parse(getSession("defaultPage"));
                  let url = this.props.location.search;
                  let query = queryString.parse(url);
                  delete query.message;
                  delete query.page;
                  let queryStr = "?" + queryString.stringify(query);
                  this.setState({ activePage: 1 });
                  this.refreshList(queryStr, defaultPage);
                }, 1000);
                this.showNotificationError(
                  "You are not allowed to delete this supplier."
                );
              } else {
                this.showNotificationError(error.response);
              }
            }
          }
        });
      } else {
        this.props.history.push("/login");
        window.location.reload();
      }
    }
  };

  refreshList(queryStr, pageId) {
    const query = queryString.parse(queryStr);
    const message = query.message;
    const checkAll = document.getElementById("check-all");
    if (checkAll) {
      checkAll.checked = false;
    }
    const checkboxes = document.querySelectorAll('input[name="item"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    if (message) {
      delete query.message;
      this.showNotification(message);
    }

    this.props.history.push("/product-supplier" + queryStr);
    const userData = JSON.parse(getSession("userData"));
    const pageInfo = JSON.parse(getSession("pageInfo"));
    const { sessionToken } = userData;
    if (userData !== null && pageInfo) {
      this.props.getSuppliers(query, sessionToken, (err, res) => {
        if (!err && res) {
          this.setState({
            suppliers: res,
            pagination: {
              limit: res.pageSize,
              total: res.totalRows,
            },
          });
        }
        this.setState({ isLoading: false });
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  renderNoPageAdded() {
    return (
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref="notify" />
        </div>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Alert color="danger">
                  <h4 className="alert-heading">No Page Added</h4>
                  <hr />
                  <p className="mb-0">
                    You have not added a page yet. Click{" "}
                    <Link to="/add-page">here</Link> to add a new page.
                  </p>
                </Alert>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  renderAddModal(supplier) {
    let { submitted } = this.state;
    return (
      <Modal
        isOpen={this.state.modal}
        toggle={this.toggleModal}
        backdrop="static"
        className={this.props.className}
        size="md"
      >
        <div className="modal-header">
          <h4 className="modal-title text-medium">
            {supplier.id ? "Edit - " + supplier.name : "Add Supplier"}
          </h4>
          <button
            type="button"
            className="close"
            onClick={this.toggleModal}
            aria-label="Close"
            style={{ color: "rgba(0, 0, 0, 0.6)" }}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <ModalBody>
          <form action="">
            {/* <Row>
              <Col sm="8">
                {this.state.addSupplierModalError && (
                  <Alert color="danger">
                    {this.state.addSupplierModalError}
                  </Alert>
                )}
              </Col>
            </Row> */}

            <Row>
              <Col md="12">
                <FormGroup
                  className={
                    submitted && !this.state.supplier.name ? " has-danger" : ""
                  }
                >
                  <label htmlFor="name" className="control-label">
                    Name
                    <em className="text-muted">(Required)</em>
                  </label>
                  <Input
                    id="name"
                    name="name"
                    placeholder="Name"
                    type="text"
                    value={this.state.supplier.name}
                    onChange={this.handleChange}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="Address" className="control-label">
                    Address
                  </Label>
                  <Input
                    id="address"
                    name="address"
                    placeholder="Address"
                    type="text"
                    value={this.state.supplier.address}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="ContactPerson" className="control-label">
                    Contact Person
                  </Label>
                  <Input
                    id="contactPerson"
                    name="contactPerson"
                    placeholder="Contact Person"
                    type="text"
                    value={this.state.supplier.contactPerson}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="ContactNumber" className="control-label">
                    Contact Number
                  </Label>
                  <Input
                    id="contactNumber"
                    name="contactNumber"
                    placeholder="Contact Number"
                    type="tel"
                    value={this.state.supplier.contactNumber}
                    onChange={this.handleChange}
                    onKeyPress={(e) => {
                      const allowedChars = /[0-9()+\- ]/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </form>
        </ModalBody>
        <ModalFooter style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button color="secondary" onClick={this.toggleModal}>
            Cancel
          </Button>{" "}
          <span></span>
          <Button
            color="info"
            onClick={
              supplier.id
                ? this.handleUpdateSupplier
                : this.handleSubmitSupplier
            }
          >
            {supplier.id ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const pageTitle = "Supplier";
    let pageInfo = JSON.parse(getSession("pageInfo"));
    if (pageInfo) {
      if (pageInfo.pageType === "5dea2304f6bba08323a3ddce") {
        this.props.history.push("/tours");
        window.location.reload();
      }
    }
    if (pageInfo && pageInfo._id) {
      if (pageInfo.isVerified === true) {
        return (
          <>
            <div className="content">
              <div className="react-notification-alert-container">
                <NotificationAlert ref="notify" />
              </div>
              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <h4 className="title">
                        {pageTitle} - <em>{pageInfo.name}</em>
                      </h4>

                      <Row>
                        <Col className="pr-md-1" md="3">
                          <FormGroup>
                            <label htmlFor="keyword" className="control-label">
                              Search
                            </label>
                            <Input
                              id="keyword"
                              name="keyword"
                              type="text"
                              placeholder="Search item..."
                              onChange={this.handleChangeKeyword}
                              onKeyPress={this.handleEnter}
                              value={this.state.keyword}
                            ></Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="pull-right">
                        <Col md="12">
                          <Button
                            alt="Add Supplier"
                            title="Add Supplier"
                            className="btn-round btn-sm"
                            color="info"
                            type="button"
                            onClick={() => this.toggleModal()}
                          >
                            Add Supplier
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Table className="tablesorter table-striped" responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>Supplier Name</th>
                            <th style={{ whiteSpace: "nowrap" }}>Address</th>
                            <th style={{ whiteSpace: "nowrap" }}>
                              Contact Person
                            </th>
                            <th style={{ whiteSpace: "nowrap" }}>
                              Contact Number
                            </th>
                            <th style={{ whiteSpace: "nowrap" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>{this.renderRows(this.state.suppliers)}</tbody>
                      </Table>
                    </CardBody>
                    <CardFooter>
                      <Row>
                        <Col md="12">
                          <Row>
                            <Col md="12" lg="12">
                              {this.state.pagination &&
                                this.state.pagination.total > 0 && (
                                  <>
                                    <Pagination
                                      innerClass="pagination"
                                      activePage={this.state.activePage}
                                      itemsCountPerPage={
                                        this.state.pagination.limit
                                      }
                                      totalItemsCount={
                                        this.state.pagination.total
                                      }
                                      pageRangeDisplayed={5}
                                      onChange={this.handlePageChange}
                                    />
                                    <p>
                                      Page <em>{this.state.activePage}</em> of{" "}
                                      <em>
                                        {Math.ceil(
                                          this.state.pagination.total /
                                            this.state.pagination.limit
                                        )}
                                      </em>{" "}
                                      of{" "}
                                      <em>
                                        {numberWithCommasOnly(
                                          this.state.pagination.total
                                        )}
                                      </em>{" "}
                                      items.
                                    </p>
                                    <Input
                                      style={{
                                        marginBottom: "5px",
                                        width: "auto",
                                      }}
                                      id="perPage"
                                      name="perPage"
                                      type="select"
                                      onChange={this.handleChangePerPage}
                                      value={this.state.size}
                                    >
                                      <option value="10">10 / page</option>
                                      <option value="25">25 / page</option>
                                      <option value="50">50 / page</option>
                                      <option value="100">100 / page</option>
                                    </Input>
                                  </>
                                )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </div>
            <LoadingOverlay
              active={this.state.isProcessing}
              spinner
              text="Processing..."
            ></LoadingOverlay>
            <LoadingOverlay
              active={this.state.isGeneratingXls}
              spinner
              text="Processing..."
            ></LoadingOverlay>
            <div className="modal-section">
              {this.renderAddModal(this.state.supplier)}
              {/* View Details Modal */}
            </div>
          </>
        );
      } else {
        return this.renderPageNotVerified();
      }
    } else {
      return this.renderNoPageAdded();
    }
  }
}

const numberWithCommasOnly = (x) => {
  return priceRound(x, 0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const priceRound = (price, dec) => {
  if (dec === undefined) {
    dec = 2;
  }
  if (price !== 0) {
    if (!price || isNaN(price)) {
      throw new Error("price is not a number" + price);
    }
  }
  const str = parseFloat(Math.round(price * 100) / 100).toFixed(dec);
  return str;
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  getSuppliers,
  deleteSupplier,
  addSupplier,
  updateSupplier,
})(ProductSupplier);
