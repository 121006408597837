//import Dashboard from "views/Dashboard.jsx";
import Products from "./components/Products/ProductList.js";
import AddProduct from "./components/Products/Add.js";
import EditProduct from "./components/Products/Edit.js";
import TourList from "./components/Tours/TourList.js";
import TourDates from "./components/TourDates/TourDateList.js";
import AddTour from "./components/Tours/AddTour.js";
import EditTour from "./components/Tours/EditTour.js";
import ImportProducts from "./components/Products/ImportProducts.js";
import ImportProductsJson from "./components/Products/ImportProductsJson.js";
import ImportProductsExcel from "./components/Products/ImportProductsExcel.js";
import ProductCategories from "./components/ProductCategory/ProductCategory.js";
import ProductSupplier from "./components/ProductSupplier/ProductSupplier.js";
import ProductMarkdown from "./components/ProductMarkdown/ProductMarkdown.js";
import AddProductMarkdown from "./components/ProductMarkdown/AddMarkdown.js";
import EditProductMarkdown from "./components/ProductMarkdown/EditMarkdown.js";
import AddProductCategory from "./components/ProductCategory/AddProductCategory.js";
import EditProductCategory from "./components/ProductCategory/EditProductCategory.js";
import AddPage from "./views/AddPage.js";
import EditPage from "./views/EditPage.js";
import Settings from "./views/Settings.js";
import ChangePassword from "./views/ChangePassword.js";
import SalesReports from "./components/Reports/SalesReports.js";
import OrderReports from "./components/Reports/OrderReports.js";
import ProductReports from "./components/Reports/ProductReports.js";
import OrdersList from "./components/Orders/OrdersList.js";
import UploadProductImages from "./components/Products/UploadProductImages.js";
import ImportCategoriesExcel from "./components/ProductCategory/ImportCategoriesExcel.js";
import SyncProductCategories from "./components/ProductCategory/SyncProductCategories.js";
import Activate from "./views/Activate.js";
import Agreement from "./views/Agreement.js";
import ProductVouchers from "./components/Products/ProductVouchers.js";
import PurchaseEntry from "./components/Inventory/PurchaseEntry.js";
import AddPurchaseEntry from "./components/Inventory/AddPurchaseEntry.js";
import EditPurchaseEntry from "./components/Inventory/EditPurchaseEntry.js";
import InventoryLevel from "./components/Inventory/InventoryLevel.js";
import Rejects from "./components/Inventory/Rejects.js";
import AddRejects from "./components/Inventory/AddRejects.js";
import Reconciliation from "./components/Inventory/Reconciliation.js";
import AddReconciliation from "./components/Inventory/AddReconciliation.js";
import EditReconciliation from "./components/Inventory/EditReconciliation.js";
import RestoredProducts from "./components/ProductRestored/RestoredProducts.js";
import AddRestoreProduct from "./components/ProductRestored/RestoreAdd.js";
import EditRejects from "./components/Inventory/EditRejects.js";
var routes = [
  {
    path: "/add-page",
    name: "Add Store",
    rtlName: "",
    icon: "tim-icons icon-single-copy-04",
    component: AddPage,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/page",
    name: "Setup Store",
    rtlName: "",
    icon: "tim-icons icon-single-copy-04",
    component: EditPage,
    layout: "/admin",
    showOnSidebar: true,
  },

  {
    path: "#",
    name: "Set Up Products",
    rtlName: "",
    icon: "tim-icons icon-app",
    component: Products,
    layout: "/admin",
    showOnSidebar: true,
  },

  {
    path: "/product-supplier",
    name: "Supplier",
    rtlName: "",
    icon: "tim-icons icon-minimal-right font-sm",
    component: ProductSupplier,
    layout: "/admin",
    child: true,
    showOnSidebar: true,
  },
  {
    path: "/product-categories",
    name: "Categories",
    rtlName: "",
    icon: "tim-icons icon-minimal-right font-sm",
    component: ProductCategories,
    layout: "/admin",
    child: true,
    showOnSidebar: true,
  },
  {
    path: "/products",
    name: "Products",
    rtlName: "",
    icon: "tim-icons icon-minimal-right font-sm",
    component: Products,
    layout: "/admin",
    child: true,
    showOnSidebar: true,
  },
  {
    path: "/product-restored",
    name: "Restored Product",
    rtlName: "",
    icon: "tim-icons icon-minimal-right font-sm",
    component: RestoredProducts,
    layout: "/admin",
    child: true,
    showOnSidebar: true,
  },
  {
    path: "/product-markdown",
    name: "Markdown",
    rtlName: "",
    icon: "tim-icons icon-minimal-right font-sm",
    component: ProductMarkdown,
    layout: "/admin",
    child: true,
    showOnSidebar: true,
  },
  {
    path: "#",
    name: "Inventory",
    rtlName: "",
    icon: "tim-icons icon-chart-bar-32",
    component: AddProduct,
    layout: "/admin",
    showOnSidebar: true,
  },
  {
    path: "/inventory-level",
    name: "Inventory Level",
    rtlName: "",
    icon: "tim-icons icon-minimal-right font-sm",
    component: InventoryLevel,
    layout: "/admin",
    child: true,
    showOnSidebar: true,
  },
  {
    path: "/inventory-reconciliation",
    name: "Reconciliation",
    rtlName: "",
    icon: "tim-icons icon-minimal-right font-sm",
    component: Reconciliation,
    layout: "/admin",
    child: true,
    showOnSidebar: true,
  },
  {
    path: "/inventory-purchases",
    name: "Purchase Entries",
    rtlName: "",
    icon: "tim-icons icon-minimal-right font-sm",
    component: PurchaseEntry,
    layout: "/admin",
    child: true,
    showOnSidebar: true,
  },
  {
    path: "/inventory-rejects",
    name: "Rejects",
    rtlName: "",
    icon: "tim-icons icon-minimal-right font-sm",
    component: Rejects,
    layout: "/admin",
    child: true,
    showOnSidebar: true,
  },
  {
    path: "/tours",
    name: "Tours",
    rtlName: "",
    icon: "tim-icons icon-app",
    component: TourList,
    layout: "/admin",
    showOnSidebar: true,
    collapse: true,
  },
  {
    path: "/tour-dates",
    name: "Tour Dates",
    rtlName: "",
    icon: "tim-icons icon-calendar-60",
    component: TourDates,
    layout: "/admin",
    showOnSidebar: true,
    collapse: true,
  },
  {
    path: "/products/new",
    name: "Add Product",
    rtlName: "",
    icon: "tim-icons icon-app",
    component: AddProduct,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/products/:_id/edit",
    name: "Edit Product",
    rtlName: "",
    icon: "tim-icons icon-app",
    component: EditProduct,
    layout: "/admin",
    showOnSidebar: false,
  },

  {
    path: "/tours/new",
    name: "Add Tour",
    rtlName: "",
    icon: "",
    component: AddTour,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/tours/:_id/edit",
    name: "Edit Tour",
    rtlName: "",
    icon: "",
    component: EditTour,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/order-list",
    name: "Orders",
    rtlName: "",
    icon: "tim-icons icon-notes",
    component: OrdersList,
    layout: "/admin",
    showOnSidebar: true,
  },
  {
    path: "/product-vouchers",
    name: "Product Vouchers",
    rtlName: "",
    icon: "tim-icons icon-tag",
    component: ProductVouchers,
    layout: "/admin",
    showOnSidebar: true,
  },
  {
    path: "/import-products",
    name: "Import Products",
    rtlName: "",
    icon: "tim-icons icon-cloud-download-93",
    component: ImportProducts,
    layout: "/ImportVoucherCodesExcel",
    showOnSidebar: true,
  },
  {
    path: "/import-products-excel",
    name: "Import Products - XLS",
    rtlName: "",
    icon: "tim-icons icon-cloud-download-93",
    component: ImportProductsExcel,
    layout: "/admin",
    showOnSidebar: true,
  },
  {
    path: "/product-categories/new",
    name: "Add Product Category",
    rtlName: "",
    icon: "",
    component: AddProductCategory,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/product-categories/:_id/edit",
    name: "Edit Product Category",
    rtlName: "",
    icon: "",
    component: EditProductCategory,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/product-markdown/new",
    name: "Add Product Markdown",
    rtlName: "",
    icon: "",
    component: AddProductMarkdown,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/product-markdown/:_id/edit",
    name: "Edit Product Markdown",
    rtlName: "",
    icon: "",
    component: EditProductMarkdown,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/inventory-reconciliation/new",
    name: "Add Reconciliation",
    rtlName: "",
    icon: "",
    component: AddReconciliation,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/inventory-reconciliation/:_id/edit",
    name: "Edit Reconciliation",
    rtlName: "",
    icon: "",
    component: EditReconciliation,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/inventory-rejects/new",
    name: "Add Reject",
    rtlName: "",
    icon: "",
    component: AddRejects,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/inventory-rejects/:_id/edit",
    name: "Edit Reject",
    rtlName: "",
    icon: "",
    component: EditRejects,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/reports/sales",
    name: "Sales Reports",
    rtlName: "",
    icon: "tim-icons icon-coins",
    component: SalesReports,
    layout: "/admin",
    showOnSidebar: true,
  },
  {
    path: "/reports/order",
    name: "Order Reports",
    rtlName: "",
    icon: "tim-icons icon-cart",
    component: OrderReports,
    layout: "/admin",
    showOnSidebar: true,
  },
  {
    path: "/reports/product",
    name: "Product Reports",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    component: ProductReports,
    layout: "/admin",
    showOnSidebar: true,
  },
  {
    path: "/settings",
    name: "Account Settings",
    rtlName: "",
    icon: "tim-icons icon-settings",
    component: Settings,
    layout: "/admin",
    showOnSidebar: true,
  },
  {
    path: "/settings/change-password",
    name: "Change Password",
    rtlName: "",
    icon: "",
    component: ChangePassword,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/product-images/bulk-upload",
    name: "Products Images",
    rtlName: "",
    icon: "tim-icons icon-cloud-upload-94",
    component: UploadProductImages,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/import-products-json",
    name: "Import Products - JSON",
    rtlName: "",
    icon: "tim-icons icon-cloud-download-93",
    component: ImportProductsJson,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/import-categories-excel",
    name: "Import Categories - XLS",
    rtlName: "",
    icon: "tim-icons icon-cloud-download-93",
    component: ImportCategoriesExcel,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/sync-product-categories",
    name: "Sync Product Categories",
    rtlName: "",
    icon: "tim-icons icon-cloud-download-93",
    component: SyncProductCategories,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/statement-of-agreement",
    name: "Statement of Agreement",
    rtlName: "",
    icon: "tim-icons icon-paper",
    component: Agreement,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/activate-store",
    name: "Activate Store",
    rtlName: "",
    icon: "tim-icons icon-check-2",
    component: Activate,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/inventory-purchases/new",
    name: "Add Product",
    rtlName: "",
    icon: "tim-icons icon-app",
    component: AddPurchaseEntry,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/inventory-purchases/:_id/edit",
    name: "Edit Purchase Entry",
    rtlName: "",
    icon: "tim-icons icon-app",
    component: EditPurchaseEntry,
    layout: "/admin",
    showOnSidebar: false,
  },
  {
    path: "/product-restored/new",
    name: "Add Restored Product",
    rtlName: "",
    icon: "tim-icons icon-app",
    component: AddRestoreProduct,
    layout: "/admin",
    showOnSidebar: false,
  },
];
export default routes;
